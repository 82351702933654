<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useForm } from '@inertiajs/inertia-vue3';
import useRotas from '../../Composables/useRotas';
import { Inertia } from '@inertiajs/inertia';

const props = defineProps({
    leilao: Object,
    lote: Object,
    habilitado: Boolean,
    habilitacoesLeilao: Array,
    habilitacoesExtraLeilao: Array,
    idsHabilitacoesClienteAprovadas: Array,
    idsHabilitacoesClienteVerificando: Array,
    idsHabilitacoesClienteReprovadas: Array,
    idsHabilitacoesCliente: Array,
    statusHabilitacaoLeilao: Object,
    idsHabilitacoesExtraClienteAprovadas: Array,
    idsHabilitacoesExtraClienteVerificando: Array,
    idsHabilitacoesExtraClienteReprovadas: Array,
    idsHabilitacoesExtraCliente: Array,
    statusHabilitacaoExtraLeilao: Object,
    documentosRequeridos: Array,
    documentosRequeridosExtra: Array,
    origem: String,
})

const route = useRotas();

const emit = defineEmits(['update:modelValue']);

const habilitandoModel = computed({
    get: () => props.modelValue,
    set: (val) => {
        Inertia.reload();
        emit('update:modelValue', val)
    }
})

const formItens = {};
if (props.documentosRequeridos){
    props.documentosRequeridos.map(
        doc => 'habilitacao_' + doc.id_habilitacao + '_doc_' + doc.id
    ).forEach((i) => {
        formItens[i] = null;
    })
}
if (props.documentosRequeridosExtra) {
    props.documentosRequeridosExtra.map(
        doc => 'habilitacao_extra_' + doc.id_habilitacao_extra + '_doc_' + doc.id
    ).forEach((i) => {
        formItens[i] = null;
    })
}
const form = useForm(formItens);
const formVerificacao = useForm({
    codigo: null
});
const $q = useQuasar();
const baixar = (url) => {
    window.open(url, '_blank');
}
</script>

<template>
    <q-dialog v-model="habilitandoModel" :maximized="$q.screen.lt.md">
            <q-card class="full-height flex no-wrap column" style="min-width: 50vw; overflow: auto">
                <q-toolbar class="bg-primary text-white">
                    <q-icon name="mdi-gavel" />
                    <q-toolbar-title>
                        Habilitação
                        <template v-if="+leilao.venda_direta === 1">
                            da Venda Direta
                        </template>
                        <template v-else>
                            do Leilão
                        </template>
                    </q-toolbar-title>
                    <q-btn flat round color="white" icon="mdi-window-close" @click="habilitandoModel = false"/>
                </q-toolbar>
                <q-form @submit="form.post(route('lote.habilitar.processar', {
                    idLeilao: leilao.id,
                    idLote: lote.id,
                    origem: (origem) ? origem : 'lote'
                }))">
                    <div>
                        <q-card-section>
                            <div class="text-overline text-primary">
                                <template v-if="+leilao.venda_direta === 1">
                                    Venda Direta
                                </template>
                                <template v-else>
                                    Leilão
                                </template>
                                {{leilao.titulo}}</div>
                            <div class="text-h5 q-mt-none q-mb-xs">
                                Habilitação {{origem}}
                            </div>
                            <div class="text-subtitle1 text-bold q-mt-sm q-mb-xs text-primary">
                                Condições de venda:
                            </div>
                            <div class="text-caption text-grey-9" v-html="leilao.descricao_do_pagamento">
                            </div>
                        </q-card-section>
                        <q-card-section :key="habilitacao.id" v-for="habilitacao in habilitacoesLeilao">
                            <div
                                class="text-subtitle1 text-bold q-mt-sm q-mb-none"
                                :class="
                                idsHabilitacoesCliente.includes(habilitacao.id)
                                    ? (idsHabilitacoesClienteAprovadas.includes(habilitacao.id)
                                        ? 'text-positive'
                                        : idsHabilitacoesClienteReprovadas.includes(habilitacao.id)
                                            ? 'text-negative'
                                            : 'text-warning'
                                    )
                                    : 'text-primary'
                            "
                            >
                                Habilitação {{habilitacao.tipo_habilitacao_leilao.nome}}
                            </div>
                            <div class="text-caption text-grey-9 flex row">
                                <div>
                                    {{habilitacao.tipo_habilitacao_leilao.instrucoes}}
                                </div>
                                <q-space></q-space>
                                <q-btn
                                    v-if="
                                        !idsHabilitacoesCliente.includes(habilitacao.id)
                                        && habilitacao?.tipo_habilitacao_leilao.arquivo
                                    "
                                    label="Instruções Detalhadas"
                                    color="primary"
                                    @click="baixar(habilitacao?.tipo_habilitacao_leilao.arquivo.viewerUrl)"
                                    flat></q-btn>
                            </div>
                            <div class="text-caption text-grey-9 flex column">
                                <template v-if="!(idsHabilitacoesCliente.includes(habilitacao.id))">
                                    <template v-if="habilitacao?.tipo_habilitacao_leilao?.documentos_requeridos_habilitacao_leilao?.length > 0">
                                        <div
                                            :key="documento.id"
                                            v-for="documento in habilitacao.tipo_habilitacao_leilao.documentos_requeridos_habilitacao_leilao">
                                            <q-file
                                                bottom-slots class="q-mx-sm"
                                                counter
                                                dense
                                                v-model="form['habilitacao_' + habilitacao.id + '_doc_' + documento.id]"
                                                :label="documento.nome"
                                                :max-files="1"
                                            >
                                                <template v-slot:prepend>
                                                    <q-icon name="mdi-folder-open-outline"/>
                                                </template>

                                                <template v-slot:hint>
                                                    máximo 1 arquivo
                                                </template>

                                                <template v-slot:append>
                                                    <q-btn v-if="documento.tipo === '1'" dense flat icon="mdi-camera" round />
                                                </template>
                                            </q-file>
                                        </div>
                                    </template>
                                </template>
                                <div v-else-if="idsHabilitacoesClienteAprovadas.includes(habilitacao.id)">
                                    <p class="text-bold text-center text-subtitle1 text-positive">
                                        Você já concluiu esta etapa da habilitação.
                                    </p>
                                </div>
                                <div v-else-if="idsHabilitacoesClienteReprovadas.includes(habilitacao.id)">
                                    <p class="text-bold text-center text-subtitle1 text-negative">
                                        Esta etapa da habilitação foi reprovada, entre em contato com o suporte.
                                    </p>
                                </div>
                                <div v-else-if="idsHabilitacoesClienteVerificando.includes(habilitacao.id)">
                                    <p class="text-bold text-center text-subtitle1 text-warning">
                                        Vocẽ recebeu um SMS com um codigo de verificação, para concluir sua habilitação, preencha o código no popup para continuar
                                    </p>
                                </div>
                                <div v-else>
                                    <p class="text-bold text-center text-subtitle1 text-warning">
                                        Esta etapa da habilitação está em aprovação.
                                    </p>
                                </div>
                            </div>
                        </q-card-section>
                        <q-card-section :key="habilitacao.id" v-for="habilitacao in habilitacoesExtraLeilao">
                            <div
                                class="text-subtitle1 text-bold q-mt-sm q-mb-none"
                                :class="
                                idsHabilitacoesExtraCliente.includes(habilitacao.id)
                                    ? (idsHabilitacoesExtraClienteAprovadas.includes(habilitacao.id)
                                        ? 'text-positive'
                                        : idsHabilitacoesExtraClienteReprovadas.includes(habilitacao.id)
                                            ? 'text-negative'
                                            : 'text-warning'
                                    )
                                    : 'text-primary'
                            "
                            >
                                Habilitação {{habilitacao.tipo_habilitacao_leilao.nome}}
                            </div>
                            <div class="text-caption text-grey-9 flex row">
                                <div>
                                    {{habilitacao.tipo_habilitacao_leilao.instrucoes}}
                                </div>
                                <q-space></q-space>
                                <q-btn
                                    v-if="
                                        !idsHabilitacoesExtraCliente.includes(habilitacao.id)
                                        && habilitacao?.tipo_habilitacao_leilao.arquivo
                                    "
                                    label="Instruções Detalhadas"
                                    color="primary"
                                    @click="baixar(habilitacao?.tipo_habilitacao_leilao.arquivo.viewerUrl)"
                                    flat></q-btn>
                            </div>
                            <div class="text-caption text-grey-9 flex column">
                                <template v-if="!(idsHabilitacoesExtraCliente.includes(habilitacao.id))">
                                    <template v-if="habilitacao?.tipo_habilitacao_leilao?.documentos_requeridos_habilitacao_leilao?.length > 0">
                                        <div
                                            :key="documento.id"
                                            v-for="documento in habilitacao.tipo_habilitacao_leilao.documentos_requeridos_habilitacao_leilao">
                                            <q-file
                                                bottom-slots class="q-mx-sm"
                                                counter
                                                dense
                                                v-model="form['habilitacao_extra_' + habilitacao.id + '_doc_' + documento.id]"
                                                :label="documento.nome"
                                                :max-files="1"
                                            >
                                                <template v-slot:prepend>
                                                    <q-icon name="mdi-folder-open-outline"/>
                                                </template>

                                                <template v-slot:hint>
                                                    máximo 1 arquivo
                                                </template>

                                                <template v-slot:append>
                                                    <q-btn v-if="documento.tipo === '1'" dense flat icon="mdi-camera" round />
                                                </template>
                                            </q-file>
                                        </div>
                                    </template>
                                </template>
                                <div v-else-if="idsHabilitacoesExtraClienteAprovadas.includes(habilitacao.id)">
                                    <p class="text-bold text-center text-subtitle1 text-positive">
                                        Você já concluiu esta etapa da habilitação.
                                    </p>
                                </div>
                                <div v-else-if="idsHabilitacoesExtraClienteReprovadas.includes(habilitacao.id)">
                                    <p class="text-bold text-center text-subtitle1 text-negative">
                                        Esta etapa da habilitação foi reprovada, entre em contato com o suporte.
                                    </p>
                                </div>
                                <div v-else-if="idsHabilitacoesExtraClienteVerificando.includes(habilitacao.id)">
                                    <p class="text-bold text-center text-subtitle1 text-warning">
                                        Vocẽ recebeu um SMS com um codigo de verificação, para concluir sua habilitação, preencha o código no popup para continuar
                                    </p>
                                </div>
                                <div v-else>
                                    <p class="text-bold text-center text-subtitle1 text-warning">
                                        Esta etapa da habilitação está em aprovação.
                                    </p>
                                </div>
                            </div>
                        </q-card-section>
                    </div>
                    <q-space></q-space>
                    <q-card-actions
                        align="center"
                        vertical
                        class="q-pt-none">
                        <q-btn
                            v-if="(idsHabilitacoesCliente.length < habilitacoesLeilao.length) || (idsHabilitacoesExtraCliente.length < habilitacoesExtraLeilao.length)"
                            class="full-width"
                            color="primary"
                            label="Solicitar Habilitação"
                            type="submit"/>
                        <q-btn
                            class="full-width"
                            color="negative"
                            label="Fechar"
                            @click="habilitandoModel = false"/>
                    </q-card-actions>
                </q-form>
            </q-card>
        <q-dialog :model-value="idsHabilitacoesClienteVerificando.length > 0 || idsHabilitacoesExtraClienteVerificando.length > 0" persistent>
            <q-card class="flex no-wrap column">
                <q-toolbar class="bg-primary text-white">
                    <q-icon name="mdi-check" />
                    <q-toolbar-title>
                        Verificação de SMS
                    </q-toolbar-title>
                </q-toolbar>
                <q-form @submit="formVerificacao.post(route('lote.habilitar.verificar', {
                    idLeilao: leilao.id,
                    idLote: lote.id
                }), { replace: true })">
                    <div>
                        <q-card-section class="flex column justify-center items-center content-center">
                            <div class="text-overline text-primary text-center">
                                <template v-if="+leilao.venda_direta === 1">Venda Direta</template>
                                <template v-else>Leilão</template>
                                {{leilao.titulo}}
                            </div>
                            <div class="text-h5 q-mt-none q-mb-xs text-center">
                                Verificando sua Identidade
                            </div>
                            <div class="text-caption text-grey-9 text-center">
                                Por segurança precisamos verificar sua identidade para liberar a habilitação neste
                                <template v-if="+leilao.venda_direta === 1">
                                    evento.
                                </template>
                                <template v-else>
                                    leilão.
                                </template>
                            </div>
                            <div class="text-caption text-grey-9 text-center">
                                Enviamos um SMS para o número cadastrado em sua conta com um código de 6 digitos, para continuar, digite o código abaixo:
                            </div>
                            <q-input v-model="formVerificacao.codigo"
                                     counter
                                     label="Digite o Código"
                                     :maxlength="6"/>
                            <div class="text-caption text-grey-9 text-center">
                                Caso tenha problemas com a verificação via SMS, enviamos também o código de solicitação em seu email.
                            </div>
                            <q-btn label="Enviar"
                                   color="primary"
                                   class="full-width q-mt-sm"
                                   type="submit" />
                            <q-btn label="Fechar"
                                   color="negative"
                                   class="full-width q-mt-xs"
                                   type="button"
                                   @click="habilitandoModel = false"
                            />
                        </q-card-section>
                    </div>
                </q-form>
            </q-card>
        </q-dialog>
    </q-dialog>
</template>
