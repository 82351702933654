<script setup>
import useRotas from '../../Composables/useRotas';

const route = useRotas();
</script>

<template>
    <div class="flex row justify-center  full-width q-pa-lg">
        <div class="full-width">
            <div
                v-if="$q.screen.gt.sm"
                class="flex bg-primary justify-center items-center text-white"
                style="height: 3em; border-top-right-radius: 5px; border-top-left-radius: 5px; margin-bottom: -2px" >
                Minha Conta
            </div>
            <q-list bordered class="rounded-borders">
                <q-item clickable @click="$inertia.visit(route('minha_conta.meusDados'))">
                    <q-item-section avatar>
                        <q-avatar
                            color="primary"
                            icon="mdi-account-circle"
                            text-color="white"
                        />
                    </q-item-section>
                    <q-item-section>
                        Meus Dados
                    </q-item-section>
                    <q-item-section
                        side
                        style="display: flex; flex-direction: row; align-content: center; justify-content: center"
                    >
                        <q-icon
                            color="grey" name="mdi-chevron-right"
                        />
                    </q-item-section>
                </q-item>
                <q-item clickable @click="$inertia.visit(route('auth.login.redefinirSenha'))">
                    <q-item-section avatar>
                        <q-avatar
                            color="primary"
                            icon="mdi-key-variant"
                            text-color="white"
                        />
                    </q-item-section>
                    <q-item-section>
                        Minha Senha
                    </q-item-section>
                    <q-item-section
                        side
                        style="display: flex; flex-direction: row; align-content: center; justify-content: center"
                    >
                        <q-icon
                            color="grey" name="mdi-chevron-right"
                        />
                    </q-item-section>
                </q-item>
                <q-item clickable @click="$inertia.visit(route('minha_conta.meusArremates'))">
                    <q-item-section avatar>
                        <q-avatar
                            color="primary"
                            icon="mdi-gavel"
                            text-color="white"
                        />
                    </q-item-section>
                    <q-item-section>
                        Minhas Compras
                    </q-item-section>
                    <q-item-section
                        side
                        style="display: flex; flex-direction: row; align-content: center; justify-content: center"
                    >
                        <q-icon
                            color="grey" name="mdi-chevron-right"
                        />
                    </q-item-section>
                </q-item>
            </q-list>
        </div>
    </div>
</template>

