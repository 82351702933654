<script setup>
import ItemTexto from './ItemTexto';
import useRotas from '../../../../../Composables/useRotas';
import {ref} from "vue";

const route = useRotas();

const doulhetresConfig = ref(window.doulheTresConfig);

</script>

<template>
    <div class="flex row justify-start">
        <item-texto texto="Home" :href="route('home')" />
        <item-texto texto="Quero Vender" :href="route('institucional.queroVender')" />
        <item-texto v-if="doulhetresConfig.link_blog && doulhetresConfig.link_blog != '#'" texto="Blog" :href="route('institucional.links.blog')"/>
        <item-texto texto="Ajuda" :href="route('institucional.ajuda')" />
        <item-texto texto="Fale Conosco" :href="route('institucional.faleConosco')" />
    </div>
</template>
