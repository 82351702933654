<script setup>
import { defineProps } from 'vue';
import useUtils from '../../Composables/useUtils';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    habilitado: Boolean,
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    formatDate,
    formatador,
} = useUtils();
</script>

<template>
    <q-card class="flex column no-wrap full-width shadow-0">
        <q-toolbar class="bg-primary text-white" :style="$q.screen.gt.sm ? '' : 'min-height: 25px;'">
            <q-icon name="mdi-gavel" />
            <q-toolbar-title :class="$q.screen.gt.sm ? '' : 'text-subtitle1'">
                Lances ({{lote?.lances?.length || '0'}} lances registrados)
            </q-toolbar-title>
        </q-toolbar>
        <q-list style="height: 40vh; overflow-y: auto;">
            <q-item v-for="(lance, idx) in lote.lances" :key="idx" clickable>

                <q-item-section avatar class="items-center" v-if="$q.screen.gt.sm">
                    <q-icon :color="(idx === 0) ? 'positive' : 'primary'" size="lg" name="mdi-cash"/>
                </q-item-section>

                <q-item-section>
                    <q-item-label v-if="idx === 0" overline>Lance Atual</q-item-label>
                    <q-item-label v-else overline>Lance</q-item-label>
                    <q-item-label :class="(idx === 0) ?
                    ($q.screen.gt.sm) ? 'text-positive text-h5' : 'text-positive text-subtitle1' :
                    ($q.screen.gt.sm) ? 'text-primary text-h6' : 'text-primary text-subtitle2'">
                        <b>{{ formatador.format(lance.valor) }}</b> por <b>{{lance.cliente.usuario.username}}</b><b style="font-size: 14px"> em {{formatDate(lance.data_hora)}}</b>
                    </q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </q-card>
</template>
<style>
.nopad > p{
    margin: 0;
}
</style>
