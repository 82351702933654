<script setup>
import ItemIcone from './ItemIcone';
import useRotas from '../../../../../Composables/useRotas';
import {ref} from "vue";

const route = useRotas();

const doulhetresConfig = ref(window.doulheTresConfig);

</script>

<template>
    <div class="flex row justify-start">
        <item-icone nome="mdi-whatsapp" blank :href="route('institucional.links.whatsapp')"/>
        <item-icone nome="mdi-instagram" blank :href="route('institucional.links.instagram')" />
        <item-icone v-if="doulhetresConfig.link_twitter && doulhetresConfig.link_twitter != '#'" nome="mdi-twitter" blank :href="route('institucional.links.twitter')" />
        <item-icone nome="mdi-youtube" blank :href="route('institucional.links.youtube')" />
        <item-icone v-if="doulhetresConfig.link_facebook && doulhetresConfig.link_facebook != '#'" nome="mdi-facebook" blank :href="route('institucional.links.facebook')" />
        <item-icone v-if="doulhetresConfig.link_linkedin && doulhetresConfig.link_linkedin != '#'" nome="mdi-linkedin" blank :href="route('institucional.links.linkedin')" />
    </div>
</template>
