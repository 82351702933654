import moment from 'moment';
import { humanizer } from 'humanize-duration';

export default function useUtils() {
    const humanizador = humanizer({
        language: "pt",
        round: true,
        conjunction: " e ",
        serialComma: false
    });
    const segundosParaIntervaloRelogio = (tempo) => {
        if(tempo <= 0) {
            return 'Atualizando...'
        }
        if (tempo > 24*60*60){
            return humanizador(tempo * 1000, { units: ['d', 'h']});
        }
        if (tempo > 60*60){
            return humanizador(tempo * 1000, { units: ['h', 'm'] });
        }
        if (tempo > 60){
            return humanizador(tempo * 1000, { units: ['h', 'm', 's'] });
        }
        return humanizador(tempo * 1000, { units: ['h', 'm', 's'] });
    }

    const baixar = (url) => {
        window.open(url, '_blank');
    }

    const getImagem = (imagem, fs, status) => {
        switch (status) {
            case 'ABERTO':
                return (fs
                    ? (imagem?.leilaoAbertoUrl?.x8)
                    : (imagem?.leilaoAbertoUrl?.x1)) || '';
            case 'EM_LOTEAMENTO':
                return (fs
                    ? (imagem?.leilaoEmLoteamentoUrl?.x8)
                    : (imagem?.leilaoEmLoteamentoUrl?.x1)) || '';
            case 'SUSPENSO':
                return (fs
                    ? (imagem?.leilaoSuspensoUrl?.x8)
                    : (imagem?.leilaoSuspensoUrl?.x1)) || '';
            case 'CANCELADO':
                return (fs
                    ? (imagem?.leilaoCanceladoUrl?.x8)
                    : (imagem?.leilaoCanceladoUrl?.x1)) || '';
            default:
                return (fs
                    ? (imagem?.leilaoAbertoUrl?.x8)
                    : (imagem?.leilaoAbertoUrl?.x1)) || '';
        }
    }
    const formatDate = (date) => {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
    };

    const formatDateWithoutSeconds = (date) => {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    };

    const formatDateWithoutHours = (date) => {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    };

    const formatador =  new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const desformatador = (valor)=>{

        valor = valor.replace(/[^0-9|^.,]/g, "");
        valor = valor.replace(/\./g, "");
        valor = valor.replace(/,/g, ".");

        return valor;
    }

    const formatadorLote = (num) => num.toString().padStart(3, '0');

    const timestampParaStringRelogio = (timestamp) =>
        (new Date(timestamp*1000 - ((new Date()).getTimezoneOffset()*60*1000))).toISOString().substr(11, 8);

    const timestampsParaIntervaloRelogio = (base, futuro) => {
        return segundosParaIntervaloRelogio(futuro - base)
    };

    const imprimir = () => {
        window.print();
    }

    const limparEntradasNulas = (obj) => {
        return Object.fromEntries(
            Object.entries(obj)
            /* eslint-disable */
            .filter(([_, v]) => v != null)
            .map(([k, v]) => [k, v === Object(v) ? limparEntradasNulas(v) : v])
        );
    }
    const isObject = (item) => {
        return (item && typeof item === 'object' && !Array.isArray(item));
    };
    const mergeDeep = (target, ...sources) => {
        if (!sources.length) return target;
        const source = sources.shift();

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return mergeDeep(target, ...sources);
    }

    const stripHTML = (html) => {
        let doc = new DOMParser().parseFromString(html || '', 'text/html');
        return doc.body.textContent || "";
    }

    const nomeStatusLote = (statusLote, acaoAtingirMetrica) => {
        const habilitaC1EC2 = +(window.doulheTresConfig.configuracoes.habilita_condicional1_e_condicional2);

        if(habilitaC1EC2 && acaoAtingirMetrica === 1){
            switch(statusLote?.identificador) {
                case 'DOULHE_UMA': return 'Condicional Uma';
                case 'DOULHE_DUAS': return 'Condicional Duas';
                default:
                    return statusLote?.nome;
            }
        }
        return statusLote?.nome;
    }

    const urlTransmisao = (leilao) => {
        if(leilao.link_transmissao){
            switch(+leilao?.id_transmicao) {
                case 1: return {'exibir_tansmissao_fora_auditorio' : 1, 'link_transmissao' : `${leilao.link_transmissao}?rel=0&autoplay=1&loop=1`};
                case 2: return {'exibir_tansmissao_fora_auditorio' : 0, 'link_transmissao' : leilao.link_transmissao};
                default:
                    return null;
            }
        }
        return null;
    }

    return {
        baixar,
        getImagem,
        formatDate,
        formatDateWithoutSeconds,
        formatDateWithoutHours,
        formatador,
        desformatador,
        formatadorLote,
        timestampParaStringRelogio,
        timestampsParaIntervaloRelogio,
        segundosParaIntervaloRelogio,
        imprimir,
        limparEntradasNulas,
        mergeDeep,
        stripHTML,
        nomeStatusLote,
        urlTransmisao
    };
}
