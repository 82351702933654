<script setup>
import { Head, useForm, usePage } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import { computed, ref } from 'vue';
import useRotas from '../../Composables/useRotas';
import FormRow from '../../Componentes/Forms/FormRow';
import FormCol from '../../Componentes/Forms/FormCol';

const route = useRotas();

const cliente = computed(() => usePage().props.value.auth.cliente)

const form = useForm({
    documentoComFoto: null,
    comprovanteDeEndereco: null,
    certidaoDeCasamento: null,
    documentoComFotoConjuge: null,
    selfieComDocumento: null,
})

const documentoComFotoRef = ref();
const comprovanteDeEnderecoRef = ref();
const certidaoDeCasamentoRef = ref();
const documentoComFotoConjugeRef = ref();
const selfieComDocumentoRef = ref();
</script>

<template>
    <Head title="Documentação Pessoa Física" />
    <BlankLayout>
        <q-page class="flex row justify-center items-center">
            <div class="flex column items-center col-11">
                <icone height="7em" width="14em" class="q-mb-md" />
                <h4 class="text-center text-primary font-weight-light no-margin q-ma-md q-py-lg">
                    Envio de Documentos
                </h4>
                <q-form class="full-width" @submit="form.post(route('documentacao.fisica.processar'))">
                    <form-row>
                        <form-col>
                            <h6 class="no-margin q-pl-xs q-pt-md text-primary">Seus Dados</h6>
                            <q-file
                                v-model="form.documentoComFoto" bottom-slots class="q-mx-sm"
                                counter
                                ref="documentoComFotoRef"
                                dense label="Documento com foto" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { documentoComFotoRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </form-col>
                        <form-col>
                            <q-file
                                v-model="form.selfieComDocumento" bottom-slots class="q-mx-sm"
                                counter
                                ref="selfieComDocumentoRef"
                                dense label="Selfie do rosto segurando o documento legível" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { documentoComFotoRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </form-col>
                        <form-col>
                            <q-file
                                v-model="form.comprovanteDeEndereco" bottom-slots class="q-mx-sm"
                                counter
                                ref="comprovanteDeEnderecoRef"
                                dense label="Comprovante de Endereço" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { comprovanteDeEnderecoRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </form-col>
                    </form-row>
                    <form-row>
                        <form-col class="flex column col-12" v-if="cliente?.estado_civil === '1'">
                            <h6 class="no-margin q-pl-xs q-pt-md text-primary">Dados do Conjuge</h6>
                            <q-file
                                v-model="form.certidaoDeCasamento" bottom-slots class="q-mx-sm"
                                counter
                                ref="certidaoDeCasamentoRef"
                                dense label="Certidão de casamento" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { certidaoDeCasamentoRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </form-col>
                        <form-col class="flex column col-12" v-if="cliente?.estado_civil === '1'">
                            <q-file
                                v-model="form.documentoComFotoConjuge" bottom-slots class="q-mx-sm"
                                counter
                                ref="documentoComFotoConjugeRef"
                                dense label="Documento com foto do Conjuge" :max-files="1"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="mdi-folder-open-outline"/>
                                </template>

                                <template v-slot:hint>
                                    máximo 1 arquivo
                                </template>

                                <template v-slot:append>
                                    <q-btn dense flat icon="mdi-plus" round @click="() => { documentoComFotoConjugeRef.getNativeElement().click() }"/>
                                </template>
                            </q-file>
                        </form-col>
                    </form-row>
                    <q-btn
                        class="full-width"
                        color="primary"
                        label="Enviar"
                        type="submit"
                        :disable="(!form.documentoComFoto || !form.comprovanteDeEndereco || !form.selfieComDocumento)
                               || ((!form.certidaoDeCasamento||!form.documentoComFotoConjuge) && (cliente?.estado_civil === '1'))"
                        :loading="form.processing"
                    />
                </q-form>
            </div>
        </q-page>
    </BlankLayout>
</template>
