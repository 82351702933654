import script from "./CardLancesTelao.vue?vue&type=script&setup=true&lang=js"
export * from "./CardLancesTelao.vue?vue&type=script&setup=true&lang=js"

import "./CardLancesTelao.vue?vue&type=style&index=0&id=28a0ebe7&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QToolbar,QIcon,QToolbarTitle,QList,QItem,QItemSection,QItemLabel});
