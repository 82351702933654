<script setup>
import { defineProps } from 'vue';
import useUtils from '../../Composables/useUtils';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';
import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    habilitado: Boolean,
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    leilao
} = useLeilaoLocalComUpdates(props)

const {
    getImagem,
    nomeStatusLote
} = useUtils();

const {
    // autoplay,
    slide,
    timerModerador,
} = useLote(leilao, lote)

const {
    logoLeiloeiro,
} = useGlobals();



</script>

<template>
    <q-card class="full-width" :class="$q.screen.gt.xs ? 'full-height' : ''">
        <q-card-section
            style="display: flex; flex-direction: row; justify-content: stretch; align-items: stretch;padding: 0"
            class="text-subtitle1 text-white text-center column justify-center">
            <div
                style="width: 100%;transition: all .75s ease-in-out"
                :style="[`background-color: ${lote.status_lote?.cor_site};`,
                        $q.screen.gt.xs ? 'height: 50px;' : 'height: 25px;'
                        ]"
                class="text-white text-center column justify-center"
                :class="$q.screen.gt.xs ? 'text-h5' : 'text-subtitle1' ">
                {{(lote.timer_moderador && timerModerador) ? timerModerador : nomeStatusLote(lote.status_lote, +leilao.acao_ao_atingir_metrica)}}
            </div>
        </q-card-section>
        <q-card-section class="q-pa-none">
            <q-card-section class="q-pa-none q-mb-xs flex row">
                <q-carousel
                    class="col-12"
                    v-model="slide"
                    autoplay
                    animated
                    arrows
                    :style="$q.screen.gt.sm ? 'height: 80vh;' : ''"
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                    infinite
                    swipeable
                >
                    <q-carousel-slide
                        v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                        :key="key" :name="1+key"
                        style="background-position: center;background-size: cover;background-repeat: no-repeat"
                        class="q-pa-none">
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="getImagem(imagem?.arquivo, true, lote.status_lote?.identificador)"
                            :ratio="16/9"
                        >
                            <template v-slot:error>
                                <div class="absolute-full flex flex-center bg-primary text-white">
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div class="absolute-full flex column flex-center bg-white text-primary">
                                    <img :src="logoLeiloeiro" style="width: 40%" />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                    </q-carousel-slide>
                </q-carousel>

            </q-card-section>

            </q-card-section>

    </q-card>
</template>
<style>
.nopad > p{
    margin: 0;
}
</style>
