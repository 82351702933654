<script setup>
import { Head, useForm } from '@inertiajs/inertia-vue3';
import MainLayout from '../../Layouts/MainLayout';
import useRotas from '../../Composables/useRotas';
import {ref} from "vue";

const route = useRotas();

const form = useForm({
    nome: null,
    email: null,
    telefone: null,
    empresa: null,
})

const emailContato = ref(window.doulheTresConfig.email_contato);
const telefoneContato = ref(window.doulheTresConfig.telefone_contato);

</script>

<template>
    <Head title="Quero Vender"/>
    <MainLayout>
        <q-page>
            <div
                class="flex q-mx-sm column wrap items-center"
            >
                <div
                    class="flex q-pt-lg row"
                >
                    <div
                        class="text-primary q-pa-none full-width"
                    >
                        <h1 class="
                                text-center text-primary text-h4 text-bold full-width
                                q-my-md
                            ">
                            Quero Vender
                        </h1>
                        <h2 class="
                                text-center text-primary text-h5 full-width
                                q-my-md
                            ">
                            Prencha seus dados e nossa equipe irá
                            entrar em contato com você o quanto antes.
                        </h2>
                    </div>
                </div>
                <div
                    :class="$q.screen.lt.sm ? '' : 'q-px-xl'"
                    class="q-pt-md no-wrap full-width flex row items-center "
                >
                    <div class="col col-12 col-md-6  q-px-md">
                        <h6 class="
                                text-center text-primary text-h6 full-width
                                q-my-md
                            ">
                            Já pensou em vender Imóveis, Veículos, Máquinas e outros bens em leilão de maneira rápida e segura?
                        </h6>
                        <p>
                            Veja abaixo como fazer um leilão e transforme seus bens em liquidez!
                        </p>
                        <p class="text-bold">
                            Como realizar um Leilão?
                        </p>
                        <p>
                            Existem 3 meios de realizar um leilão:
                        </p>
                        <p class="text-bold">
                            Leilão Presencial
                        </p>
                        <p>
                            O leilão será realizado em local específico, com data, local e horário definidos.
                            Não serão aceitos lances pela internet, telefone e outros meios de comunicação.
                        </p>
                        <p class="text-bold">
                            Leilão Online
                        </p>
                        <p>
                            Na modalidade Online os lances são ofertados somente atravite.
                            Para aés do nosso s abertura e fechamento do leilão existem datas e horários estabelecidos, que devem ser observados com atenção.
                            Para participar desta modalidade é necessário o cadastro em nosso site e o interessado deverá solicitar a habilitação na página do próprio leilão.
                            A disponibilização dos lances acontece de 10 a 30 dias antes do encerramento.
                            No dia do encerramento é recomendável que o login seja feito com antecedência e que todo o leilão seja acompanhado pela Sala de Disputa.
                            Durante o leilão nossa equipe estará à disposição, para dar o suporte necessário a nossos clientes.
                            Ao término, nossa equipe entra em contato com os arrematantes, dando suporte pós-leilão com os procedimentos para finalização e pagamento dos lotes arrematados.
                        </p>
                        <p class="text-bold">
                            Leilão Presencial e Online (Simultâneo)
                        </p>
                        <p>
                            Nesta modalidade, os lances são aceitos através da internet e do local da realização do leilão.
                            O leilão acontece e é finalizado simultaneamente.
                        </p>
                        <p class="text-center text-bold">
                            Entre em contato conosco através do e-mail {{ emailContato }} ou pelo telefone {{ telefoneContato }}
                        </p>
                    </div>
                    <div class="flex col col-12 col-md-6 q-px-md">
                        <h6 class="
                                text-center text-primary text-h6 full-width
                                q-my-md
                            ">
                            Preencha o formulário e entraremos em contato
                        </h6>
                        <q-form
                            @submit="form.post(route('institucional.queroVender.enviar'))"
                            class="full-width q-px-md"
                        >
                            <q-input
                                v-model="form.nome"
                                class="q-mb-sm"
                                filled
                                label="Seu Nome"
                                hint="Nome completo"
                                lazy-rules
                                :rules="[ val => val && val.length > 0 || 'Por favor, digite um nome']"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <q-input
                                v-model="form.email"
                                class="q-mb-sm"
                                filled
                                type="email"
                                label="Email"
                                hint="Digite seu email"
                                unmasked-value
                                lazy-rules
                                :rules="[
                                        val => val && val.length > 0 || 'Por favor, digite um email',
                                   ]"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <q-input
                                filled
                                class="q-mb-sm"
                                v-model="form.telefone"
                                mask="(##) #####-####"
                                hint="Primeiro DD e depois o número: (##) #####-####"
                                label="Telefone"
                                unmasked-value
                                lazy-rules
                                :rules="[
                                        val => val && val.length > 0 || 'Por favor, digite um telefone',
                                    ]"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <q-input
                                filled
                                class="q-pb-none"
                                v-model="form.empresa"
                                label="Empresa"
                                hint="Digite o nome da sua empresa"
                                unmasked-value
                                lazy-rules
                                :rules="[
                                        val => val && val.length > 0 || 'Por favor, digite o nome da empresa comitente',
                                   ]"
                            >
                                <template v-slot:append>
                                    <q-icon color="red" size="15px" name="mdi-asterisk"/>
                                </template>
                            </q-input>
                            <div class="flex row justify-around q-mt-lg">
                                <q-btn style="flex:3.5;" type="submit" color="primary">
                                    Enviar
                                </q-btn>
                                <q-btn
                                    class="q-ml-sm"
                                    @click="form.reset()"
                                    style="flex: 1.5;"
                                    label="Limpar" type="reset"
                                    text-color="primary"
                                />
                            </div>
                        </q-form>
                    </div>
                </div>
            </div>
        </q-page>
    </MainLayout>
</template>
