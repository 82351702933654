import script from "./CardFatura2.vue?vue&type=script&setup=true&lang=js"
export * from "./CardFatura2.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QExpansionItem,QItemSection,QIcon,QSeparator});
