import { computed, ref } from 'vue';
import useRotas from '../Composables/useRotas';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import {Inertia} from "@inertiajs/inertia";

export default function useAuditorio(leilao, loteInit, lotesInit, ignorarSeguir = false, rotaFixar = 'moderador.lei.lote') {
    const route = useRotas();
    const lanceLocal = ref(false);
    const loteLeiloeiro = ref(leilao.id_lote_leiloeiro_momento);
    const verLances = ref(false);
    const lotes = computed(() => lotesInit);
    const qtdLotes = computed(() => lotesInit.length);
    // const mostrarImagens = computed(() => qtdLotes.value < 15);
    const mostrarImagens = ref(true);
    const $q = useQuasar();
    const lote = ref(loteInit);
    const manterIgnorarSeguir = ref(ignorarSeguir);

    const abrir = async (idLote) => {
        if (+lote.value.id === +idLote){
            if (!$q.loading.isActive){
                $q.loading.show({
                    message: 'Atualizando...',
                });
            }
        } else {
            if (!$q.loading.isActive){
                $q.loading.show({
                    message: 'Navegando para o lote ' + idLote,
                });
            }
        }
        lote.value = await (await fetch(route('lote.info', { idLeilao: leilao.id, idLote}))).json();
        $q.loading.hide();
        document.getElementsByClassName(`card-${idLote}`)[0].scrollIntoView()
    };

    const abrirFixar = async (idLote, idLeilao = leilao.id) => {
        manterIgnorarSeguir.value = true;
        if (+lote.value.id === +idLote){
            if (!$q.loading.isActive){
                $q.loading.show({
                    message: 'Atualizando...',
                });
            }
        } else {
            if (!$q.loading.isActive){
                $q.loading.show({
                    message: (+idLeilao === +leilao.id) ? 'Navegando para o lote ' + idLote + ' do leilão ' + idLeilao : 'Navegando para o leilão ' + idLeilao,
                });
            }
        }
        Inertia.get(route(rotaFixar, {
            idLeilao: +idLeilao,
            idLeilaoAnterior: +leilao.id,
            idLote: (+idLeilao === +leilao.id) ? idLote : null
        }),{}, {
            onSuccess: () => {
                $q.loading.hide();
                setTimeout(() => {
                    manterIgnorarSeguir.value = false;
                }, 3000)
            }
        });
    };

    const abrirEvent = async (e) => {
        await abrir(e);
    };

    const abrirFixarEvent = async (e, a) => {
        await abrirFixar(e, a);
    };

    const seguirLeiloeiroModel = ref(!(localStorage.getItem('seguir') === 'nao'));
    localStorage.setItem('seguir', seguirLeiloeiroModel.value ? 'sim' : 'nao')
    const seguirLeiloeiro = computed({
        get: () => !manterIgnorarSeguir.value && seguirLeiloeiroModel.value,
        set: (val) => {
            seguirLeiloeiroModel.value = val;
            localStorage.setItem('seguir', seguirLeiloeiroModel.value ? 'sim' : 'nao')
        }
    });

    setTimeout(() => {
        document.getElementsByClassName('atual')[0].scrollIntoView()
    }, 1500)

    return {
        lanceLocal,
        verLances,
        qtdLotes,
        mostrarImagens,
        abrir,
        abrirEvent,
        abrirFixarEvent,
        seguirLeiloeiro,
        lotes,
        lote,
        loteLeiloeiro
    }
}
