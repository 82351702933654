<script setup>
//import { usePage } from '@inertiajs/inertia-vue3';
//import Filtros from '../../Componentes/Home/Filtros';
import MainLayout from '../../Layouts/MainLayout';
import { defineProps, ref, watch, onMounted} from 'vue';
//import ListaCategorias from '../../Componentes/Home/ListaCategorias';
import { useQuasar } from 'quasar';
import qs from 'qs';
import ListaLotesArrematadosSemLeilao from './ListaLotesArrematadosSemLeilao';
import MenuMinhaConta from '../../Componentes/MinhaConta/MenuMinhaConta';

const props = defineProps({
    rotaDadosLotes: String,
    textoHeader: String,
    textosPagina: Object,
    filtros: Object,
    filtrosOpcoes: Object,
});

//const cliente = computed(() => usePage().props.value.auth.cliente)
//const logado = computed(() => usePage().props.value.auth?.autenticado);

const $q = useQuasar();
//const showDialog = ref(false);
/*
const toggleFilter = () => {
    showDialog.value = !showDialog.value
};
*/
const tab = 'lotes';
const infiniteScrollLeiloes = ref(null);
const infiniteScrollLotes = ref(null);

const tudoCarregadoLeiloes = ref(false);
const leiloesCarregados = ref([]);
const tudoCarregadoLotes = ref(false);
const lotesCarregados = ref([]);

const loadDataLotes = async (index, done) => {
    if (!tudoCarregadoLotes.value){
        const lotes = await (await fetch(props.rotaDadosLotes+ '?' + qs.stringify({
            page: index,
            ...props.filtros
        }), {
            method: 'GET',
            credentials: 'include'
        })).json();
        lotesCarregados.value.push(...lotes)
        if (lotes.length === 0){
            tudoCarregadoLotes.value = true;
            infiniteScrollLotes.value.stop()
        }
        done()
    } else {
        infiniteScrollLotes.value.stop()
        done()
    }
}

watch(props, () => {
    tudoCarregadoLeiloes.value = false;
    tudoCarregadoLotes.value = false;
    leiloesCarregados.value = [];
    lotesCarregados.value = [];
    if(tab.value === 'leiloes'){
        infiniteScrollLeiloes.value.reset()
        infiniteScrollLeiloes.value.resume()
        infiniteScrollLeiloes.value.trigger()
    } else {
        infiniteScrollLotes.value.reset()
        infiniteScrollLotes.value.resume()
        infiniteScrollLotes.value.trigger()
    }
})

onMounted(() =>{
    if(tab.value === 'leiloes'){
        infiniteScrollLeiloes.value.trigger();
    } else {
        infiniteScrollLotes.value.trigger();
    }
})

</script>

<template>
    <MainLayout :title="(textoHeader || 'Meus Arremates')">
        <q-page class="q-py-md">
            <div class="flex column justify-center content-stretch items-center">
                <h4
                    class="text-center text-primary font-weight-light q-ma-md q-mb-lg"
                    :class="$q.screen.gt.sm ? 'text-h4' : 'text-h5'">
                    Minhas Compras
                </h4>
            </div>

            <div class="flex row justify-start content-stretch">

                <div class="flex column col-3" v-if="$q.screen.gt.sm">
                    <MenuMinhaConta/>
                </div>

                <div class="flex column" :class="$q.screen.gt.sm ? 'col-9' : 'col-12'">
                    <div class="flex row justify-center full-width q-pa-sm" v-show="tab === 'lotes'">

                        <q-infinite-scroll :disable="tab !== 'lotes'" class="full-width"
                                           @load="loadDataLotes" :initial-index="0" :offset="850" ref="infiniteScrollLotes">

                            <lista-lotes-arrematados-sem-leilao :lotes="lotesCarregados" v-if="tab === 'lotes'" />

                            <template v-slot:loading>
                                <div class="full-width row justify-center q-my-md">
                                    <div class="column justify-center items-center content-center">
                                        <q-spinner-dots color="primary" size="60px" />
                                        <p class="text-primary">Carregando mais lotes...</p>
                                    </div>
                                </div>
                            </template>
                        </q-infinite-scroll>

                        <template v-if="tudoCarregadoLotes && lotesCarregados.length === 0">
                            <div class="full-width row justify-center q-my-md">
                                <div class="column justify-center items-center content-center">
                                    <p class="text-primary">Ainda não há lotes arrematados por você, caso seu lance tenha sido o vencedor, estaremos computando seus arremates.</p>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="tudoCarregadoLotes">
                            <div class="full-width row justify-center q-my-md">
                                <div class="column justify-center items-center content-center">
                                    <p class="text-primary">Estes são todos os lotes arrematados por você!</p>
                                </div>
                            </div>
                        </template>

                    </div>
                </div>

            </div>



        </q-page>
    </MainLayout>
</template>
