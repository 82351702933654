<script setup>
import {Head, useForm} from '@inertiajs/inertia-vue3';
import {defineProps, onBeforeUnmount, onMounted, ref, watch, computed} from 'vue';
import useUtils from '../Composables/useUtils';
import Lance from '../Componentes/Leilao/Lance';
import useAuditorio from '../Composables/useAuditorio';
import { useQuasar } from 'quasar';
import CardLances from "../Componentes/Auditorio/CardLances";
import BtnLote from "../Componentes/Auditorio/BtnLote";
import CardDetalhesModerador from "../Componentes/Auditorio/CardDetalhesModerador";
import SlimNoFooterNoHeaderLayout from "../Layouts/SlimNoFooterNoHeaderLayout.vue";
import useLeilaoLocalComUpdates from "../Composables/useLeilaoLocalComUpdates";
import useRotas from "@/Composables/useRotas";

const props = defineProps({
    leilao: Object,
    loteSelecionadoInit: Object,
    lotesInit: Array,
    lotesCompleto: Object,
    habilitado: Boolean,
})

const route = useRotas();

const $q = useQuasar();

const {
    leilao: leilaoSock
} = useLeilaoLocalComUpdates({
    leilaoInit: props.leilao
})

const {
    formatDate,
    desformatador,
    formatador,
    formatadorLote
} = useUtils();

const {
    lanceLocal,
    verLances,
    mostrarImagens,
    seguirLeiloeiro,
    lotes,
    abrirFixarEvent,
    loteLeiloeiro,
    lote
} = useAuditorio(
    props.leilao,
    props.loteSelecionadoInit,
    props.lotesInit,
    true,
    'moderador.mod.lote'
)

let leiloeiroAndou = (e) => {
    loteLeiloeiro.value = e.id;
    if (seguirLeiloeiro.value) {
        $q.loading.show({
            message: '<p>Seguindo leiloeiro...<br/> Para desativar este comportamento, clique no seletor no canto superior direito da tela</p>',
            html: true
        });
        lote.value = e.dados;
        setTimeout(() => {
            $q.loading.hide();
            document.getElementsByClassName('atual')[0].scrollIntoView()
        }, 800)

    }
}

window.Echo.channel('auditorio.' + props.leilao.id)
.listen('.leiloeiroAndou', (e) => {
    leiloeiroAndou({ id: e.novoLote, dados: e.dadosNovoLote });
});

onMounted(() => {
    document.body.style.overflowY = 'hidden';
    document.body.style.zoom = '90%';
})

onBeforeUnmount(() =>{
    leiloeiroAndou = () => {};
    document.body.style.overflowY = 'auto';
    document.body.style.zoom = '100%';
})

const iniciarChatEvento = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.iniciarChatEvento', { idLeilao: lote.value.id_leilao }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
        });
    $q.loading.hide();
}

const interromperChatEvento = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.interromperChatEvento', { idLeilao: lote.value.id_leilao }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
        });
    $q.loading.hide();
}

const iniciarStream = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.iniciarStream', { idLeilao: lote.value.id_leilao }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
        });
    $q.loading.hide();
}
const interromperStream = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.interromperStream', { idLeilao: lote.value.id_leilao }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
        });
    $q.loading.hide();
}

const urlStream = ref(leilaoSock.value.link_transmissao);
const idLeilaoNovo = ref(null);

const atualizarStream = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.atualizarStream', { idLeilao: lote.value.id_leilao }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({link: urlStream.value})
        });

    $q.loading.hide();
}

const mudarLeilao = async () => {
    abrirFixarEvent(null, idLeilaoNovo.value)
}

const placaIdentificacaoValida = ref(true);
const mensagemPlacaIdentificacaoValida = ref('');


const form = useForm({
    identificacaoPresencial: null,
    valorPresencial: formatador.format(props.loteSelecionadoInit.proximoLance),
});

const incrementarValor = () => {
    form.valorPresencial = formatador.format(Number.parseFloat(desformatador(form.valorPresencial)) + Number.parseFloat(lote.value.incrementoLote) );
};

const decrementarValor = () => {

    // console.log(form.valorPresencial);

    if(Number.parseFloat(lote.value.proximoLance) < Number.parseFloat(desformatador(form.valorPresencial))) {
        form.valorPresencial = formatador.format(Number.parseFloat(desformatador(form.valorPresencial)) - Number.parseFloat(lote.value.incrementoLote));
    }else{
        form.valorPresencial = formatador.format(Number.parseFloat(lote.value.proximoLance));
    }

    // console.log(form.valorPresencial);

};


watch(props, () => {
    form.valorPresencial = formatador.format(props.loteSelecionadoInit.proximoLance);
});


const insereLancePresencial = async () => {

    form.valorPresencial = +desformatador(form.valorPresencial);

    $q.loading.show({
        message: 'Gravando Lance Presencial...'
    })
    const resposta = await fetch(
        route('auditorio.lancePresencial', { idLeilao: lote.value.id_leilao, idLote: lote.value.id }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify(form.data())
        });

    const json = await resposta.json();
    placaIdentificacaoValida.value = (json.mensagem === 'OK');
    mensagemPlacaIdentificacaoValida.value = json.mensagem;

    form.identificacaoPresencial = '';
    form.valorPresencial = formatador.format(props.loteSelecionadoInit.proximoLance);

    $q.loading.hide();
}

const atualizacaoRelevante = async () => {

    $q.loading.show({
        message: 'Atualizando para todos os clientes...'
    })
     await fetch(
        route('moderador.mod.lote.atualizacaoRelevanteLoteModerador', { idLeilao: lote.value.id_leilao, idLote: lote.value.id }),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({})
        });

    $q.loading.hide();
}

const valorAvisoValorVenda = ref();
const avisoValorVenda = ref();
const optionsAvisoValorVenda = ref([]);
const optionsSeletorAvisoValorVenda = computed(() => (props.lotesCompleto.original || []).map(i =>
    ({ label: `${formatadorLote(i.sequencia)} - ${i.titulo?.toUpperCase() || ''}`,
        value: i.id,
        proximoLance: i.proximoLance,
        titulo: i
    })
));
const filtrarOpcoesSeletorAvisoValorVenda = (val, update) => {
    update(() => {
        optionsAvisoValorVenda.value = optionsSeletorAvisoValorVenda.value.filter(i => i.label.toUpperCase().includes(val.toUpperCase()));
    })
}

watch(avisoValorVenda, (val) => {
    valorAvisoValorVenda.value = +val.proximoLance*100;
})

onMounted(() => {
});


</script>

<template>
    <Head :title="'Moderador de Leilão - ' + lote.titulo"/>
    <SlimNoFooterNoHeaderLayout>
        <q-page class="flex column">
            <div class="flex q-mt-md q-ml-md q-mr-md ">
                <div style="flex: 1; ">
                    <q-btn
                        dense
                        color="primary"
                        class="q-pl-lg q-pr-lg q-mr-md"
                        label="Mudar Leilão"
                        icon="mdi-gavel"
                    >
                        <q-popup-proxy style="overflow-y: hidden">
                            <q-banner style="width: 300px">
                                <div class="full-width text-center q-mb-sm text-primary">
                                    Mudar Leilão
                                </div>
                                <q-input
                                    label="Nº do Leilão"
                                    class="full-width q-mb-sm"
                                    v-model="idLeilaoNovo" dense outlined>
                                    <template v-slot:append>
                                        <q-btn

                                            color="positive"
                                            flat
                                            round
                                            @click="mudarLeilao"
                                            icon="mdi-arrow-right-box" />
                                    </template>
                                </q-input>
                            </q-banner>
                        </q-popup-proxy>
                    </q-btn>
                    <q-btn
                        v-if="1===2"
                        dense
                        color="info"
                        class="q-pl-lg q-pr-lg"
                        label="Aviso Valor de Venda"
                        icon="mdi-access-point"
                    >
                        <q-popup-proxy style="overflow-y: hidden; background-color: red;">
                            <q-banner style="width: 400px">
                                <div class="full-width text-center q-mb-sm text-primary">
                                    Aviso Valor de Venda por Lote
                                </div>

                                <q-select
                                    filled
                                    outlined
                                    dense
                                    v-model="avisoValorVenda"
                                    use-input
                                    input-debounce="0"
                                    label="Lote"
                                    :options="optionsAvisoValorVenda"
                                    @filter="filtrarOpcoesSeletorAvisoValorVenda"
                                    class="full-width q-mb-sm"
                                >
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No results
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                                <q-input
                                    prefix="R$"
                                    mask="###########.##"
                                    reverse-fill-mask
                                    unmasked-value
                                    label="Valor do Lance Inicial"
                                    class="full-width q-mb-sm"
                                    v-model="valorAvisoValorVenda"
                                    dense outlined>
                                </q-input>
                                <q-btn
                                    :disabled="!avisoValorVenda"
                                    color="positive full-width q-mb-sm"
                                    label="Avisar todos do Auditório"
                                    @click="mudarLeilao"
                                    icon-right="mdi-send" />
                            </q-banner>
                        </q-popup-proxy>
                    </q-btn>
                </div>
                <div style="flex: 1; text-align: right;" >
                    <q-btn
                        dense
                        color="info"
                        class="q-pl-lg q-pr-lg q-mr-md q-ml-md"
                        label="Chat"
                        icon="mdi-chat"
                    >
                        <q-popup-proxy style="overflow-y: hidden">
                            <q-banner style="width: 650px">
                                <div class="full-width text-center q-mb-sm text-primary">
                                    Chat ao Vivo
                                </div>

                                <div style="text-align: center">
                                    <template v-if="+leilaoSock.chat_evento_iniciado === 1">
                                        <div style="margin-bottom:5px;">
                                            <q-btn
                                                dense
                                                color="negative"
                                                class="q-pl-lg q-pr-lg q-mr-md q-ml-md"
                                                label="Interromper Chat para o Evento"
                                                icon="mdi-chat"
                                                @click="interromperChatEvento"
                                            />
                                        </div>
                                        <template v-if="!leilaoSock.chat_url_evento">
                                            <div>
                                                <q-spinner-comment
                                                    color="primary"
                                                    size="2em"
                                                />
                                                <q-tooltip :offset="[0, 8]">QSpinnerComment</q-tooltip>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <iframe :src="`${leilaoSock?.chat_url_evento}&id_evento=1&remover_avatar=1`"
                                                    style="width: 100%; height: 750px; border: none;"></iframe>
                                        </template>

                                    </template>
                                    <template v-else>
                                        <q-btn
                                            dense
                                            color="positive"
                                            class="q-pl-lg q-pr-lg q-mr-md q-ml-md"
                                            label="Iniciar Chat para o Evento"
                                            icon="mdi-chat"
                                            @click="iniciarChatEvento"
                                        />
                                    </template>
                                </div>

                            </q-banner>
                        </q-popup-proxy>
                    </q-btn>
                    <q-btn
                        class="q-ml-md"
                        dense
                        color="positive"
                        v-if="+leilaoSock.transmissao_iniciada === 0"
                        @click="iniciarStream"
                        label="Iniciar Stream Configurada"
                        icon="mdi-play"
                    />
                    <q-btn
                        class="q-ml-md"
                        dense
                        color="negative"
                        @click="interromperStream"
                        v-if="+leilaoSock.transmissao_iniciada === 1"
                        label="Interromper Stream Configurada"
                        icon="mdi-stop"
                    />
                    <q-btn
                        class="q-ml-md"
                        dense
                        color="primary"
                        label="URL da Stream"
                        icon="mdi-cog-play"
                    >
                        <q-popup-proxy style="overflow-y: hidden">
                            <q-banner style="width: 25vw">
                                <q-input
                                    class="full-width"
                                    v-model="urlStream" dense outlined>
                                    <template v-slot:append>
                                        <q-btn
                                            color="positive"
                                            flat
                                            round
                                            @click="atualizarStream"
                                            icon="mdi-content-save-check" />
                                    </template>
                                </q-input>
                            </q-banner>
                        </q-popup-proxy>
                    </q-btn>

                </div>
            </div>
            <div class="full-width flex row justify-between items-center">
                <div class="flex column q-px-md items-start">
                    <h6 class="q-ma-none text-primary">
                        Moderador de Leilão {{ leilao.id }}  "{{ leilao.titulo }}"
                    </h6>
                    <div class="q-mb-none" v-html="leilao.descricao"></div>
                </div>
            </div>
            <div class="full-width flex row wrap q-pt-md q-px-xs" style="flex: 1">
                <div class="flex column col-8 q-pa-sm">
                    <card-detalhes-moderador :key="lote.id" :lote-init="lote" :leilao-init="leilao"
                                             :habilitado="habilitado"
                                             :lotes="lotes"
                    >
                        <template v-slot:botoes-lote>
                            <btn-lote @selecionado="abrirFixarEvent"
                                      :lote-leiloeiro="+loteLeiloeiro"
                                      v-for="loteCard in lotes"
                                      :key="loteCard.id"
                                      :lote-init="loteCard"
                                      :leilao="leilao"
                                      :mostrar-imagens="mostrarImagens"
                                      :lote-selecionado="lote"
                            />
                        </template>
                    </card-detalhes-moderador>
                </div>




                <div class="flex column col-4 full-height" >

                    <div class="column full-width"
                         scroll-region>
                        <div class="flex column full-width q-pa-sm">
                            <card-lances :key="lote.id" :lote-init="lote" :leilao-init="leilao"
                                         :habilitado="habilitado" />
                        </div>
                    </div>

                    <div class="column full-width q-pb-md" style="height: 25vh;">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-gavel" />
                                <q-toolbar-title>
                                    Ações Lance Presencial
                                </q-toolbar-title>
                            </q-toolbar>
                            <div style="height: 30vh; overflow-y: auto;" class="q-pa-sm">
                                <div style="width: 100%;" class="q-my-none q-pa-xs">
                                    <q-form @submit="insereLancePresencial"  style="display: flex;flex-direction: row;flex-wrap: wrap; justify-content: space-between;">
                                        <div style="width: 40%;" class="q-my-none q-pr-sm">
                                            <q-input
                                                :error="!placaIdentificacaoValida"
                                                :error-message="mensagemPlacaIdentificacaoValida"
                                                outlined
                                                dense
                                                class="full-width"
                                                v-model="form.identificacaoPresencial"
                                                map-options
                                                emit-value
                                                label="Placa"></q-input>
                                        </div>
                                        <div style="width: 60%;" class="q-mr-none">


                                                <q-input

                                                         v-model="form.valorPresencial"
                                                         label="Valor do Lance Presencial"
                                                         prefix="R$"
                                                         mask="###.###.###.###,##"
                                                         reverse-fill-mask
                                                         input-class="text-right"

                                                        :rules="[() => (desformatador(form.valorPresencial) >= props.loteSelecionadoInit.proximoLance) || 'Valor deve ser maior do que o lance anterior']"
                                                         :error="!placaIdentificacaoValida"
                                                         :error-message="mensagemPlacaIdentificacaoValida"
                                                         outlined
                                                         dense

                                                >
                                                    <template v-slot:append>
                                                        <q-btn icon="mdi-minus-box" @click="decrementarValor" round flat dense/>
                                                        <q-btn icon="mdi-plus-box" @click="incrementarValor" round flat dense/>
                                                    </template>
                                                </q-input>

                                        </div>
                                        <div style="width: 100%;" class="q-my-none q-pl-xs">
                                            <q-btn
                                                color="primary"
                                                class="full-width"
                                                label="Adicionar Lance Presencial"
                                                icon="mdi-plus"
                                                type="submit"
                                            />
                                        </div>
                                    </q-form>
                                </div>
                            </div>
                        </q-card>
                    </div>

                    <div class="column full-width q-pb-md" style="height: 15vh; display:none">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-gavel" />
                                <q-toolbar-title>
                                    Leilão Geral
                                </q-toolbar-title>
                            </q-toolbar>
                            <div style="height: 30vh; overflow-y: auto;" class="q-pa-sm">
                                <div style="width: 100%;" class="q-my-none q-pa-xs">
                                        <div style="width: 100%;" class="q-my-none q-pl-xs">
                                            <q-btn
                                                color="secondary"
                                                class="full-width"
                                                label="Atualização para todos clientes"
                                                icon="mdi-access-point"
                                                type="button"
                                                @click="atualizacaoRelevante"
                                            />
                                        </div>
                                </div>
                            </div>
                        </q-card>
                    </div>

                </div>

            </div>
            <Lance
                v-model="lanceLocal"
                :leilao="lote.leilao"
                :lote="lote"
                :habilitado="habilitado"
            />
            <q-dialog v-model="verLances">
                <q-card class="flex no-wrap column"
                        style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
                    <q-toolbar class="bg-primary text-white">
                        <q-icon name="mdi-gavel"/>
                        <q-toolbar-title>
                            Lances no lote "{{ lote.titulo }}"
                        </q-toolbar-title>
                        <q-btn flat round color="white" icon="mdi-window-close"
                               @click="verLances = false"/>
                    </q-toolbar>
                    <q-list style="max-height: 65vh; overflow-y: auto;">
                        <q-item v-for="(lance, idx) in lote.lances" :key="idx" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon :color="(idx === 0) ? 'positive' : 'primary'" size="lg"
                                        name="mdi-cash"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label v-if="idx === 0" overline>Lance Atual</q-item-label>
                                <q-item-label v-else overline>Lance</q-item-label>
                                <q-item-label
                                    :class="(idx === 0) ? 'text-positive text-h5' : 'text-primary text-h6'">
                                    {{ formatador.format(lance.valor) }}
                                </q-item-label>
                                <q-item-label overline>
                                    por <b
                                    :class="(lance.cliente.usuario.username === usernameLogado) ? 'text-positive' : 'text-primary'">{{ lance.cliente.usuario.username }}</b>
                                    em <b>{{ formatDate(lance.data_hora) }}</b>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </q-dialog>
        </q-page>
    </SlimNoFooterNoHeaderLayout>
</template>
