<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import MainLayout from '../../Layouts/MainLayout';
import { useQuasar } from 'quasar';
import {defineProps, ref} from "vue";

const $q = useQuasar();

const props = defineProps({
    perguntasFrequentesPt1: String,
    perguntasFrequentesPt2: String,
});


const FaqQuestions = ref(JSON.parse(props.perguntasFrequentesPt1));
const FaqQuestions2 = ref(JSON.parse(props.perguntasFrequentesPt2));

</script>

<template>
    <Head title="Ajuda" />
    <MainLayout>
        <q-page>
            <div>
                <div class="flex justify-center">
                    <h1 class="
                          text-center text-primary text-h4 text-bold full-width
                          q-my-md q-pt-lg
                      ">
                        Perguntas frequentes
                    </h1>
                    <h2 class="
                          text-center text-primary text-h5 full-width
                          q-my-md
                      ">
                        Repostas para as dúvidas mais comuns de nossos usuários!
                    </h2>
                </div>
                <div style="min-height: 40vh">
                    <div
                        :class="$q.screen.lt.sm ? 'column' : ''"
                        class="flex"
                    >
                        <div
                            class="flex q-mt-sm"
                            style="flex:1;">
                            <q-expansion-item
                                v-for="(FaqQuestion, id) in FaqQuestions"
                                :key="id"
                                :label="FaqQuestion.label"
                                class="full-width"
                                expand-separator
                                header-class="text-primary"
                                icon="mdi-chat-question"
                            >
                                <q-card>
                                    <q-card-section v-html="FaqQuestion.resposta">

                                    </q-card-section>
                                </q-card>
                            </q-expansion-item>
                        </div>
                        <div
                            :class="$q.screen.lt.sm ? '' : 'q-mt-sm'"
                            class="flex"
                            style="flex:1;"
                        >
                            <q-expansion-item
                                v-for="(FaqQuestion, id) in FaqQuestions2"
                                :key="id"
                                :label="FaqQuestion.label"
                                class="full-width"
                                expand-separator
                                header-class="text-primary"
                                icon="mdi-chat-question"
                            >
                                <q-card>
                                    <q-card-section v-html="FaqQuestion.resposta">

                                    </q-card-section>
                                </q-card>
                            </q-expansion-item>
                        </div>
                    </div>
                </div>
            </div>
        </q-page>
    </MainLayout>
</template>
