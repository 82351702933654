import script from "./MeusArremates.vue?vue&type=script&setup=true&lang=js"
export * from "./MeusArremates.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInfiniteScroll,QSpinnerDots});
