<script setup>
import { computed } from 'vue';
import { Inertia } from '@inertiajs/inertia';
import useFiltroLotes from '../../../Composables/useFiltroLotes';
import useRotas from '../../../Composables/useRotas';
import { usePage } from '@inertiajs/inertia-vue3';
import {useQuasar} from 'quasar';

const route = useRotas();
const $q = useQuasar();

const leilao = computed(() => usePage().props.value.leilao);
const lotesSeletor = computed(() => usePage().props.value.lotesInit || []);
const {
    modeloSeletor,
    opcoesSeletor,
    filtrarOpcoesSeletor
} = useFiltroLotes(lotesSeletor, (idLote) => {
    Inertia.visit(route('auditorio.lote', {
        idLeilao: leilao.value.id,
        idLote: idLote,
    }), {
        preserveScroll: true
    })

    if($q.screen.gt.sm){
        document.getElementsByClassName(`card-${idLote}`)[0].scrollIntoView();
    }

});
</script>

<template>
    <q-toolbar elevated class="bg-primary text-white q-pa-xs shadow-2" dense>
        <div class="flex full-width row justify-center items-center">
            <q-select
                :style="!$q.screen.lt.md ? 'width: 70vw;' : 'width: 90vw;'"
                dense
                filled
                use-input
                bg-color="white"
                color="secondary"
                label-color="primary"
                @filter="filtrarOpcoesSeletor"
                v-model="modeloSeletor"
                :options="opcoesSeletor"
                label="Ir para o Lote"/>
        </div>
    </q-toolbar>
</template>
