<script setup>
import {computed, onMounted, ref} from "vue";
import {QPopupProxy, useQuasar} from "quasar";
import {usePage} from "@inertiajs/inertia-vue3";
import useRotas from "@/Composables/useRotas";


// const page = computed(() => usePage());
// console.log(page);

// const cliente = computed(() => usePage().props.value.auth.cliente);
// const usuario = computed(() => usePage().props.value.auth.usuario);
// const nomeUsuario = usuario?.value.username;


const chatIniciado = computed(() => usePage().props.value.leilao?.chat_evento_iniciado);
const chatId = computed(() => usePage().props.value.leilao?.chat_id_evento);


const route = useRotas();

const $q = useQuasar();

// const urlChatBase = 'https://fastflow-webchat-dev.pages.dev';

// const chatAberto = ref(true);
const popupProxy = ref(false);

const linkChat = ref();

const linkChatEventoCliente = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    const resposta =
        await fetch(
        route('auditorio.linkChatEventoCliente', { idLeilao: 1750}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body:JSON.stringify({idEvento: chatId.value})
        });

    linkChat.value = await resposta.json();

    $q.loading.hide();
}

onMounted(() => {
    window.addEventListener('message', (event) => {

        if (event.data.type === 'updateValor' && !event.data.valor) {
            popupProxy.value?.hide()
        }
    });

    linkChatEventoCliente();

});

</script>

<template>

    <div class="q-pa-sm">
        <q-btn v-if="+chatIniciado === 1"
            dense
            color="info"
            class="q-pl-lg q-pr-lg q-mr-sm"
            :label="'Chat'"
            icon="mdi-chat"
        ><KeepAlive>
            <q-popup-proxy ref="popupProxy" style="overflow-y: hidden">
                <q-banner style="width: 550px">
                    <div class="full-width text-center q-mb-sm text-primary">
                        Chat ao Vivo
                    </div>
                    <div>
                        <iframe :src="linkChat.link"
                                style="width: 100%; height: 600px; border: none;"></iframe>
                    </div>
                </q-banner>
            </q-popup-proxy>
        </KeepAlive>
        </q-btn>

    </div>

</template>
