<script setup>
import {defineProps} from 'vue';
import useUtils from '../../Composables/useUtils';
import { useQuasar } from 'quasar';
import GoogleMap from '../../Componentes/GoogleMap';

import useRotas from '../../Composables/useRotas';

import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import LeilaoLayout from '../../Layouts/LeilaoLayout';
import useLeilaoLocalComUpdates from "../../Composables/useLeilaoLocalComUpdates";

const props = defineProps({
    loteInit: Object,
    habilitado: Boolean,
    habilitacoesLeilao: Array,
    habilitacoesExtraLeilao: Array,
    idsHabilitacoesClienteAprovadas: Array,
    idsHabilitacoesClienteVerificando: Array,
    idsHabilitacoesClienteReprovadas: Array,
    idsHabilitacoesCliente: Array,
    statusHabilitacaoLeilao: Object,
    idsHabilitacoesExtraClienteAprovadas: Array,
    idsHabilitacoesExtraClienteVerificando: Array,
    idsHabilitacoesExtraClienteReprovadas: Array,
    idsHabilitacoesExtraCliente: Array,
    statusHabilitacaoExtraLeilao: Object,
    documentosRequeridos: Array,
    documentosRequeridosExtra: Array,
    lotesIndice: Array,
})

const {
    lote,
    leilao
} = useLoteLocalComUpdates(props);


const {
    leilao: leilaoSock
} = useLeilaoLocalComUpdates({
    leilaoInit: leilao
})

const $q = useQuasar();

const route = useRotas();

const {
    getImagem,
    formatDate,
    formatador,
    formatadorLote,
    baixar,
    nomeStatusLote
} = useUtils();

const {
    logoLeiloeiro,
    urlAtual,
    logado,
    usernameLogado
} = useGlobals();

const {
    timer,
    slide,
    tab,
    edital,
    outrosDocumentos,
    vistoria,
    share,
    lanceUsuario,
    lanceData,
    lanceProprio,
    verLances,
    verDocumentos,
    imprimir,
    timerModerador,
    lanceAtualAutomatico
} = useLote(leilao, lote)


</script>

<template>
    <LeilaoLayout :title="formatadorLote(lote.sequencia) + ' - ' + lote.titulo" :description="lote.descricao">
        <q-page class="q-py-md">

            <div class="flex column justify-center content-stretch items-center">
                <h4
                    class="text-center text-primary font-weight-light q-ma-md q-mb-lg"
                    :class="$q.screen.gt.sm ? 'text-h4' : 'text-h5'">
                    Informações do Lote Arrematado
                </h4>
            </div>

            <div class="flex row full-width q-pa-sm">
                <div class="flex column col-12 col-md-6">
                    <q-responsive class="full-width" :ratio="16/9">
                        <div :style="
                            `position: absolute;
                            top: 0px;
                            width: 100%!important;
                            height: 40px!important;
                            z-index: 998;
                            background-color: ${lote.status_lote.cor_site};`
                        ">
                            <p style="color: white; font-size: 25px;font-weight: 300;text-align: center;">
                                {{ nomeStatusLote(lote.status_lote, +leilao.acao_ao_atingir_metrica) }}
                            </p>
                        </div>
                        <q-carousel
                            v-model="slide"
                            animated
                            arrows
                            control-color="white"
                            control-text-color="primary"
                            control-type="push"
                            next-icon="mdi-chevron-right"
                            prev-icon="mdi-chevron-left"
                            infinite
                            navigation
                            style="height: 27em;"
                        >
                            <q-carousel-slide
                                v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0                ? lote.imagens_lote
                : (lote.leilao.comitente.arquivo_capa_padrao ? [{arquivo: lote.leilao.comitente.arquivo_capa_padrao}] : []))"
                                :key="key"
                                :name="((+leilaoSock.transmissao_iniciada === 1)?2:1)+key"
                                class="q-pa-none">
                                <q-img
                                    class="full-width full-height"
                                    loading="lazy"
                                    :src="getImagem(imagem?.arquivo, true, lote.status_lote?.nome)"
                                    :ratio="16/9"
                                >
                                    <template v-slot:error>
                                        <div class="absolute-full flex flex-center bg-primary text-white">
                                            Houve um problema carregando a imagem
                                        </div>
                                    </template>
                                    <template v-slot:loading>
                                        <div class="absolute-full flex column flex-center bg-white text-primary">
                                            <img :src="logoLeiloeiro" style="width: 40%" />
                                            <p class="q-mt-sm">
                                                <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                            </p>
                                        </div>
                                    </template>
                                </q-img>
                                <div class="absolute-bottom seq-caption q-pb-xl">
                                    <h5>{{formatadorLote(lote.sequencia)}}</h5>
                                </div>
                                <q-carousel-control v-if="+leilao.fechamento_automatico === 1 && lote.proximoStatus.timestamp">
                                    <q-chip :color="lote.proximoStatus.cor" text-color="white">
                                        {{lote.proximoStatus.texto}},  {{timer}}
                                    </q-chip>
                                </q-carousel-control>
                                <q-carousel-control v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador">
                                    <q-chip :color="lote.proximoStatus.cor" text-color="white">
                                        {{ timerModerador }}
                                    </q-chip>
                                </q-carousel-control>
                            </q-carousel-slide>
                        </q-carousel>
                    </q-responsive>
                    <div v-if="$q.screen.gt.sm" class="flex row justify-around q-ma-sm full-width">
                        <div class="row justify-evenly q-pt-xs full-width">
                            <q-card-section v-if="edital"
                                @click="baixar(edital.arquivo.signedUrl)"
                                class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Edital
                                </h6>
                            </q-card-section>
                            <q-separator v-if="edital" inset vertical/>
                            <q-card-section v-if="vistoria"
                                @click="baixar(vistoria.arquivo.signedUrl)"
                                class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Vistoria
                                </h6>
                            </q-card-section>
                            <q-separator v-if="vistoria" inset vertical/>
                            <q-card-section v-if="outrosDocumentos"
                                @click="verDocumentos = true"
                                class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-file-document-multiple"
                                    size="2.5em"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Todos
                                </h6>
                            </q-card-section>
                        </div>
                    </div>
                </div>
                <div class="flex column col-12 col-md-6">

                    <div class="flex row items-center q-pa-none full-width wrap" style="justify-content: space-between;">
                        <div class="flex row items-center q-px-md q-pt-md q-pb-none" style="flex: 1">
                            <span class="text-primary q-pr-sm">
                                Em {{formatDate(lote.leilao.data_hora_inicio)}}
                            </span>
                            <span class="text-primary q-px-sm"
                                  style="border: 1px solid #1E4A7D; border-radius: 5px">
                             {{lote.leilao?.localidade?.nome|| 'ONLINE'}}
                            </span>
                            <q-img
                                :src="lote.leilao?.comitente?.arquivo?.logoComitenteUrl"
                                height="35px"
                                width="100px"
                                fit="contain">
                            </q-img>
                        </div>

                    </div>


                    <div class="flex column q-px-md q-pt-none">
                        <div>
                            <q-list>

                                <q-item clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-gavel"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label overline>Leilão</q-item-label>
                                        <q-item-label  class="text-h6 text-primary">
                                            Leilão {{ leilao.id }} - {{ leilao.titulo }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-item clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-text"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label overline>Título do Lote Arrematado</q-item-label>
                                        <q-item-label  class="text-h6 text-primary">
                                            Lote {{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-item clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-calendar"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-tooltip>
                                            A primeira praça deste leilão acontecerá em {{
                                                formatDate(lote.leilao?.data_hora_inicio)
                                            }}
                                        </q-tooltip>
                                        <q-item-label  overline>1º Leilão</q-item-label>
                                        <q-item-label  >{{
                                                formatDate(lote.leilao?.data_hora_inicio)
                                            }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-item clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon  size="lg" color="positive" name="mdi-cash"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label overline>Valor do Arremate {{(lanceAtualAutomatico) ? '- Automático' : ''}}</q-item-label>
                                        <q-item-label  class="text-h4 text-positive">
                                            {{ formatador.format(lote.lanceAtual) }}
                                        </q-item-label>
                                        <q-item-label overline>
                                            por <b :class="(lanceProprio) ? 'text-positive' : 'text-primary'">{{lanceUsuario}}</b> em <b>{{lanceData}}</b>
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-item v-if="lote.leilao.dois_leiloes === '1'"
                                        clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-calendar"/>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-tooltip >
                                            A segunda praça deste leilão acontecerá em {{
                                                formatDate(lote.leilao?.data_hora_inicio_segundo_leilao)
                                            }}
                                        </q-tooltip>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>2º Leilão</q-item-label>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{ formatDate(lote.leilao?.data_hora_inicio_segundo_leilao) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    v-if="lote.leilao.dois_leiloes === '1'"
                                    clickable
                                >
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="positive" name="mdi-currency-usd"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" >Valores do 2º Leilão</q-item-label>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Lance Inicial {{ formatador.format(lote.lance_inicial_segundo_leilao) }}
                                        </q-item-label>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Incremento {{ formatador.format(lote.incremento_segundo_leilao) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </div>
                        <div class="flex column q-mt-md">
                            <q-btn
                                v-if="logado && 1===2"
                                :class="'bg-positive q-mt-sm'"
                                @click="$inertia.visit(route('auditorio.lote', {idLeilao: lote.leilao.id, idLote: lote.id}))"
                                class="text-white full-width hide-print"
                                label="Instruções para Pagamento"
                            />
                            <div class="flex row content-stretch items-stretch full-width">
                                <div class="flex column q-pr-sm"
                                     v-if="logado && 1===2"
                                     style="flex: 1">
                                    <q-btn
                                        v-if="logado"
                                        :class="'q-mt-sm'"
                                        @click="$inertia.visit(route('auditorio.lote', {idLeilao: lote.leilao.id, idLote: lote.id}))"
                                        class="text-white full-width q-mt-sm bg-primary hide-print"
                                        label="Nota de venda"
                                    />
                                </div>
                                <div class="flex column"
                                     :class="lote.leilao.status_leilao.identificador !== 'EM_PREGAO' ? '' : ''"
                                     style="flex: 1">
                                    <q-btn
                                        @click="verLances = true"
                                        class="text-white full-width q-mt-sm bg-primary hide-print"
                                        color="primary"
                                        label="Ver histórico dos seus Lances"
                                    />
                                </div>
                            </div>
                            <q-btn
                                v-show="false"
                                class="text-white full-width q-mt-sm bg-primary hide-print"
                                label="Agendar Visitação"
                            />
                            <div class="row justify-evenly q-pt-xs">
                                <q-card-section @click="solicitarLaudo" v-if="false && +lote.tipo_lote === 2" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        :class="logado ? 'cursor-pointer' : 'cursor-not-allowed disabled'"
                                        name="mdi-file-lock-open"
                                        size="2.5em"
                                    >
                                        <q-tooltip v-if="!logado">
                                            Você deve estar logado para adquirir um laudo.
                                        </q-tooltip>
                                    </q-icon>
                                    Comprar laudo veicular
                                </q-card-section>
                                <q-separator @click="solicitarLaudo" v-if="false && +lote.tipo_lote === 2" inset vertical/>
                                <q-card-section @click="imprimir" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        class="cursor-pointer"
                                        name="mdi-printer"
                                        size="2.5em"
                                    >
                                    </q-icon>
                                    imprimir página
                                </q-card-section>
                                <q-separator inset vertical/>
                                <q-card-section @click="share = true" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        class="cursor-pointer"
                                        name="mdi-share-variant"
                                        size="2.5em"
                                    >
                                    </q-icon>
                                    Compartilhar lote
                                    <q-dialog  v-model="share">
                                        <q-card class="q-pa-md flex column">
                                            <ShareNetwork
                                                class="q-my-sm full-width"
                                                network="facebook"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                style="text-decoration: none;"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    style="text-decoration: none;"
                                                    color="primary"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-facebook"
                                                    label="Compartilhar no Facebook" />
                                            </ShareNetwork>
                                            <ShareNetwork
                                                class="q-my-sm full-width"
                                                network="twitter"
                                                style="text-decoration: none;"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    color="info"
                                                    style="text-decoration: none;"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-twitter"
                                                    label="Compartilhar no Twitter" />
                                            </ShareNetwork>
                                            <ShareNetwork
                                                style="text-decoration: none;"
                                                class="q-my-sm full-width"
                                                network="whatsapp"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    style="text-decoration: none;"
                                                    color="positive"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-whatsapp"
                                                    label="Compartilhar no Whatsapp" />
                                            </ShareNetwork>
                                        </q-card>
                                    </q-dialog>
                                </q-card-section>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$q.screen.lt.md" class="flex row justify-around q-ma-sm full-width">
                    <div class="row justify-evenly q-pt-xs full-width">
                        <q-card-section v-if="edital"
                            @click="baixar(edital.arquivo.signedUrl)"
                            class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Edital
                            </h6>
                        </q-card-section>
                        <q-separator v-if="edital" inset vertical/>
                        <q-card-section v-if="vistoria"
                            @click="baixar(vistoria.arquivo.signedUrl)"
                            class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Vistoria
                            </h6>
                        </q-card-section>
                        <q-separator v-if="vistoria" inset vertical/>
                        <q-card-section v-if="outrosDocumentos"
                            @click="verDocumentos = true"
                            class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-file-document-multiple"
                                size="2.5em"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Todos
                            </h6>
                        </q-card-section>
                    </div>
                </div>
            </div>
            <q-separator class="q-mb-lg"/>
            <div class="flex row full-width">
                <h2 class="full-width text-center q-mt-sm text-h4 q-px-md">
                    Detalhes do Lote - {{ lote.titulo }} </h2>
            </div>

            <div v-html="lote?.descricao" class="q-mx-md"></div>
            <q-separator class="q-my-lg"/>

            <div class="flex row full-width">
                <q-expansion-item class="text-center full-width bg-grey-2 q-mx-sm" label="Condições de Venda">
                    <q-card class="q-pa-sm" bordered>
                        <h6 class="no-margin no-padding text-center">Condições de Venda</h6>
                        <div v-html="lote?.leilao.descricao_do_pagamento"></div>
                    </q-card>
                </q-expansion-item>
            </div>
            <div v-show="false" class="flex row full-width">
                <div class="flex column items-center"
                     style="flex:1;">
                    <q-icon
                        class="cursor-pointer"
                        color="primary"
                        name="mdi-calendar-month"
                        size="2.5em"
                    ></q-icon>
                    <span>Ano</span>
                    <span class="text-secondary">2021</span>
                </div>
                <div class="flex column items-center"
                     style="flex:1;">
                    <q-icon
                        class="cursor-pointer"
                        color="primary"
                        name="mdi-gas-station"
                        size="2.5em"></q-icon>
                    <span>Tipo de combustível</span>
                    <span class="text-secondary">Gasolina</span>
                </div>
                <div class="flex column items-center"
                     style="flex:1;">
                    <q-icon
                        class="cursor-pointer"
                        color="primary"
                        name="mdi-car-settings"
                        size="2.5em"></q-icon>
                    <span>Classificação</span>
                    <span class="text-secondary">Automóveis</span>
                </div>
            </div>
            <div class="flex row full-width">
                <q-list class="full-width">
                    <q-expansion-item
                        class="text-center bg-grey-2 q-mt-md"
                        label="Imagens, Video e Panoramas"
                    >
                        <q-separator/>
                        <div :style="$q.screen.lt.sm ? 'width: 100vw' : 'width: 100%'" class="q-gutter-y-md">
                            <q-tabs
                                v-model="tab" class="text-primary"
                                inline-label
                                mobile-arrows
                                outside-arrows
                            >
                                <q-tab icon="mdi-image" label="Images" name="image"/>
                                <q-tab icon="mdi-video-image" label="Videos" name="videos"/>
                                <q-tab icon="mdi-panorama" label="Panoramas" name="panorama"/>
                            </q-tabs>
                            <q-tab-panels v-model="tab" :style="$q.screen.lt.md ? '' : 'height: 75vh'">
                                <q-tab-panel
                                    class="flex row justify-center" name="image"
                                >
                                    <q-responsive v-if="lote.imagens_lote.length > 0" :ratio="16/14" :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 60vw'">
                                        <q-carousel
                                            v-model="slide"
                                            animated infinite navigation
                                            arrows
                                            control-color="white"
                                            control-text-color="primary"
                                            control-type="push"
                                            next-icon="mdi-chevron-right"
                                            prev-icon="mdi-chevron-left"
                                        >
                                            <q-carousel-slide
                                                v-for="(imagem, key) in (lote.imagens_lote
                                                ? lote.imagens_lote
                                                : [])"
                                                :key="key"
                                                :img-src="imagem?.arquivo.leilaoAbertoUrl.x8"
                                                :name="1+key">
                                            </q-carousel-slide>
                                        </q-carousel>
                                    </q-responsive>
                                    <q-responsive v-else :ratio="16/14" :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 60vw'">
                                        Nenhuma imagem adicionada
                                    </q-responsive>
                                </q-tab-panel>
                                <q-tab-panel name="videos">
                                    <q-responsive :ratio="16/9">
                                        Nenhum video adicionado
                                    </q-responsive>
                                </q-tab-panel>
                                <q-tab-panel name="panorama">
                                    Nenhum panorama adicionado
                                </q-tab-panel>
                            </q-tab-panels>
                        </div>
                    </q-expansion-item>
                    <q-separator/>
                    <q-expansion-item class="text-center bg-grey-2 q-mt-md" label="Local de exposição">
                        <div
                            :class="$q.screen.lt.sm ? 'column q-pa-sm' : 'q-ma-sm'"
                            class="flex"
                        >
                            <div
                                v-if="lote?.leilao?.localidade?.nome !== 'ONLINE'"
                                :class="$q.screen.lt.sm ? 'full-width' : ''"
                                style="flex: 1;"
                            >
                                <google-map :nome-local="lote?.leilao?.localidade?.nome"/>
                            </div>
                            <q-separator v-if="lote?.leilao?.localidade?.nome !== 'ONLINE'"/>
                            <div
                                class="full-width flex column q-px-md items-center justify-center"
                                style="flex: 1;"
                            >
                                <h6 class="no-margin no-padding text-center">{{ lote?.leilao?.localidade?.nome }}</h6>
                                <p class="text-center">
                                    {{
                                        lote.leilao.texto_local
                                    }}
                                </p>
                            </div>
                        </div>
                    </q-expansion-item>
                    <q-separator/>
                </q-list>
            </div>



            <q-dialog v-model="verLances">
                <q-card class="flex no-wrap column" style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
                    <q-toolbar class="bg-primary text-white">
                        <q-icon name="mdi-gavel" />
                        <q-toolbar-title v-if="lote.lances[0]">Lances no lote "{{lote.titulo}}"</q-toolbar-title>
                        <q-toolbar-title v-else>Sem lances para o lote "{{lote.titulo}}"</q-toolbar-title>
                        <q-btn flat round color="white" icon="mdi-window-close" @click="verLances = false"/>
                    </q-toolbar>
                    <q-list style="max-height: 65vh; overflow-y: auto;">
                        <q-item v-for="(lance, idx) in lote.lances" :key="idx" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon :color="(idx === 0) ? 'positive' : 'primary'" size="lg" name="mdi-cash"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label v-if="idx === 0" overline>Lance Atual</q-item-label>
                                <q-item-label v-else overline>Lance</q-item-label>
                                <q-item-label  :class="(idx === 0) ? 'text-positive text-h5' : 'text-primary text-h6'">
                                    {{ formatador.format(lance.valor) }}
                                </q-item-label>
                                <q-item-label overline>
                                    por <b :class="(lance.cliente.usuario.username === usernameLogado) ? 'text-positive' : 'text-primary'">{{lance.cliente.usuario.username}}</b> em <b>{{formatDate(lance.data_hora)}}</b>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </q-dialog>
            <q-dialog v-model="verDocumentos">
                <q-card class="flex no-wrap column" style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
                    <q-toolbar class="bg-primary text-white">
                        <q-icon name="mdi-gavel" />
                        <q-toolbar-title>Todos os documentos</q-toolbar-title>
                        <q-btn flat round color="white" icon="mdi-window-close" @click="verDocumentos = false"/>
                    </q-toolbar>
                    <q-list style="max-height: 65vh; overflow-y: auto;">

                        <q-item  v-if="outrosDocumentos.length === 0 ">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" size="lg" name="mdi-close-thick"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label overline>Sem anexos</q-item-label>
                                <q-item-label color="primary" class="text-h5">
                                    Nenhum documento encontrado
                                </q-item-label>
                            </q-item-section>
                        </q-item>



                        <q-item v-for="(documento, idx) in outrosDocumentos" :key="idx"
                                clickable
                                @click="baixar(documento.arquivo.signedUrl)">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" size="lg" name="mdi-download"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label overline>{{ {'0': 'Vistorias', '1': 'Outros'}[documento.tipo] }}</q-item-label>
                                <q-item-label color="primary" class="text-h5">
                                    {{ documento.descricao }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </q-dialog>

        </q-page>
    </LeilaoLayout>
</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 8px
    padding-bottom: 60px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h5
    margin: 0
.nopad > p
    margin: 0
</style>
