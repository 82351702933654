import script from "./BotaoChat.vue?vue&type=script&setup=true&lang=js"
export * from "./BotaoChat.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QPopupProxy,QBanner});
