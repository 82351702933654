<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';
import useRotas from '../../../../Composables/useRotas';
import { useQuasar } from 'quasar';

const usuario = computed(() => usePage().props.value.auth.usuario)
const route = useRotas();
const $q = useQuasar();



</script>

<template>
    <div class="flex row justify-between items-center content-center bloco-usuario">

        <div class="q-pa-sm">
            <q-btn color="primary" icon="mdi-account-circle" clickable>
                <label class="cursor-pointer q-ml-md" v-if="!$q.screen.lt.sm">Minha Conta</label>
                <q-menu>
                    <div class="row no-wrap q-pa-md">

                        <div class="column">
                            <div class="text-h6 q-mb-md row justify-center text-primary" style="width: 100%">Área do Cliente</div>
                            <q-list style="min-width: 200px">
                                <q-item clickable v-close-popup :href="route('minha_conta.meusDados')">
                                    <q-item-section avatar>
                                        <q-avatar text-color="primary" icon="mdi-account-circle"></q-avatar>
                                    </q-item-section>
                                    <q-item-section avatar>
                                        Meus Dados
                                    </q-item-section>
                                </q-item>

                                <q-item clickable v-close-popup :href="route('auth.login.redefinirSenha')">
                                    <q-item-section avatar>
                                        <q-avatar text-color="primary" icon="mdi-key-variant"></q-avatar>
                                    </q-item-section>
                                    <q-item-section>Alterar Senha</q-item-section>
                                </q-item>

                                <q-item clickable v-close-popup :href="route('minha_conta.meusArremates')">
                                    <q-item-section avatar>
                                        <q-avatar text-color="primary" icon="mdi-gavel"></q-avatar>
                                    </q-item-section>
                                    <q-item-section>Minhas Compras</q-item-section>
                                </q-item>

                            </q-list>
                            <q-separator class="q-mx-sm" v-if="$q.screen.lt.sm" />
                            <div class="column row justify-center items-center"
                            v-if="$q.screen.lt.sm">
                                <div class="text-subtitle1 q-mt-md q-mb-xs ">{{usuario.username}}</div>
                                <q-btn
                                    color="primary"
                                    label="Sair"
                                    push
                                    size="sm"
                                    v-close-popup
                                    clickable
                                    @click="$inertia.visit(route('auth.sair'))"
                                />
                            </div>
                        </div>

                        <q-separator vertical inset class="q-mx-lg"
                                     v-if="!$q.screen.lt.sm"/>

                        <div class="flex column justify-center items-center"
                             v-if="!$q.screen.lt.sm">

                            <q-avatar size="72px">
                                <q-icon size="72px"
                                        name="mdi-account-circle" color="primary"/>
                            </q-avatar>

                            <div class="text-subtitle1 q-mt-md q-mb-xs">{{usuario.username}}</div>

                            <q-btn
                                color="primary"
                                label="Sair"
                                push
                                size="sm"
                                v-close-popup
                                clickable
                                @click="$inertia.visit(route('auth.sair'))"
                            />


                        </div>

                    </div>
                </q-menu>
            </q-btn>
        </div>
    </div>
</template>

<style type="text/sass" scoped>
.bloco-usuario{
    cursor: pointer;
    transition: 0.3s;
}
.bloco-usuario:hover{
    background-color: rgba(255, 255, 255, 0.10);
}
</style>
