<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import {defineProps, onBeforeUnmount, onMounted} from 'vue';
// import useUtils from '../Composables/useUtils';
import Lance from '../Componentes/Leilao/Lance';
import CardLote from '../Componentes/Auditorio/CardLote';
import CardDetalhes from '../Componentes/Auditorio/CardDetalhes';
import useAuditorio from '../Composables/useAuditorio';
import AuditorioLayout from '../Layouts/AuditorioLayout';
import { useQuasar } from 'quasar';
import {Inertia} from "@inertiajs/inertia";
import useRotas from '../Composables/useRotas';

const props = defineProps({
    leilao: Object,
    loteSelecionadoInit: Object,
    lotesInit: Array,
    habilitado: Boolean,
    habilitacoesLeilao: Array,
    habilitacoesExtraLeilao: Array,
    idsHabilitacoesClienteAprovadas: Array,
    idsHabilitacoesClienteVerificando: Array,
    idsHabilitacoesClienteReprovadas: Array,
    idsHabilitacoesCliente: Array,
    statusHabilitacaoLeilao: Object,
    idsHabilitacoesExtraClienteAprovadas: Array,
    idsHabilitacoesExtraClienteVerificando: Array,
    idsHabilitacoesExtraClienteReprovadas: Array,
    idsHabilitacoesExtraCliente: Array,
    statusHabilitacaoExtraLeilao: Object,
    documentosRequeridos: Array,
    documentosRequeridosExtra: Array,
})

const route = useRotas();

const $q = useQuasar();

//const {
//    formatDate,
//} = useUtils();

const {
    lanceLocal,
    // verLances,
    mostrarImagens,
    seguirLeiloeiro,
    lotes,
    abrirEvent,
    loteLeiloeiro,
    lote
} = useAuditorio(
    props.leilao,
    props.loteSelecionadoInit,
    props.lotesInit
)

let leiloeiroAndou = (e) => {
    loteLeiloeiro.value = e.id;
    if (seguirLeiloeiro.value) {
        $q.loading.show({
            message: '<p>Seguindo leiloeiro...<br/> Para desativar este comportamento, clique no seletor no canto superior direito da tela</p>',
            html: true
        });
        lote.value = e.dados;
        setTimeout(() => {
            $q.loading.hide();
            document.getElementsByClassName('atual')[0].scrollIntoView()
        }, 800)

    }
}

let leiloeiroMudouLeilao = (e) => {
    if(+props.leilao.id === +e.idLeilaoAnterior){
        if (seguirLeiloeiro.value) {
            Inertia.get(route('auditorio.lote', {idLeilao: e.idLeilao}),
                {}, {
                    onSuccess: () => {
                    }
                });
        }
    }
}

window.Echo.channel('auditorio.' + props.leilao.id)
.listen('.leiloeiroAndou', (e) => {
    leiloeiroAndou({ id: e.novoLote, dados: e.dadosNovoLote });
});

window.Echo.channel('auditorio')
.listen('.leiloeiroMudouLeilao', (e) => {
    leiloeiroMudouLeilao({ idLeilao: e.novoLeilao, idLeilaoAnterior: e.leilaoAnterior });
});

onMounted(() => {
    // document.body.style.overflowY = 'hidden';
})

onBeforeUnmount(() =>{
    leiloeiroAndou = () => {};
    leiloeiroMudouLeilao = () => {};
    // document.body.style.overflowY = 'auto';
})
</script>
<template>
    <Head :title="'Auditorio - ' + lote.titulo"/>
    <AuditorioLayout>
        <q-page class="flex column">
            <div class="full-width flex row justify-between items-center">
                <div class="flex column q-px-md q-mt-lg items-start">
                    <h6 class="q-ma-none text-primary" :class="($q.screen.gt.sm) ? '' : 'text-subtitle2'">
                        Auditório do leilão {{ leilao.id }} "{{ leilao.titulo }}"
                    </h6>
                    <div class="q-mb-none" v-html="leilao.descricao"></div>
                </div>
                <div class="full-height q-mr-md flex column justify-center">
                    <q-toggle
                        :true-value="true"
                        :false-value="false"
                        v-model="seguirLeiloeiro"
                        label="Seguir Leiloeiro"/>
                </div>
            </div>
            <div class="full-width flex row wrap q-pt-md q-px-xs" style="flex: 1">
                <div v-if="$q.screen.gt.sm" class="column full-height col-6" style="max-height: 80vh; overflow-y: auto"
                    >
                    <div class="column wrap full-height full-width"
                         style="max-height: 80vh; overflow-y: auto" scroll-region>
                        <div class="flex column full-width listalotes">
                            <card-lote
                                @selecionado="abrirEvent"
                                :lote-leiloeiro="+loteLeiloeiro"
                                v-for="loteCard in lotes"
                                :key="loteCard.id"
                                :lote-init="loteCard"
                                :leilao="leilao"
                                :mostrar-imagens="mostrarImagens"
                                :lote-selecionado="lote"
                                :habilitado="habilitado"/>
                        </div>
                    </div>
                </div>
                <div class="flex column q-pa-sm" :class="($q.screen.gt.sm) ? 'col-6' : 'col-12'">
                    <card-detalhes :key="lote.id"
                                   :lote-init="lote"
                                   :leilao-init="leilao"
                                   :habilitado="habilitado"

                                   :habilitacoesLeilao="habilitacoesLeilao"
                                   :habilitacoesExtraLeilao="habilitacoesExtraLeilao"
                                   :idsHabilitacoesClienteAprovadas="idsHabilitacoesClienteAprovadas"
                                   :idsHabilitacoesClienteVerificando="idsHabilitacoesClienteVerificando"
                                   :idsHabilitacoesClienteReprovadas="idsHabilitacoesClienteReprovadas"
                                   :idsHabilitacoesCliente="idsHabilitacoesCliente"
                                   :statusHabilitacaoLeilao="statusHabilitacaoLeilao"
                                   :idsHabilitacoesExtraClienteAprovadas="idsHabilitacoesExtraClienteAprovadas"
                                   :idsHabilitacoesExtraClienteVerificando="idsHabilitacoesExtraClienteVerificando"
                                   :idsHabilitacoesExtraClienteReprovadas="idsHabilitacoesExtraClienteReprovadas"
                                   :idsHabilitacoesExtraCliente="idsHabilitacoesExtraCliente"
                                   :statusHabilitacaoExtraLeilao="statusHabilitacaoExtraLeilao"
                                   :documentosRequeridos="documentosRequeridos"
                                   :documentosRequeridosExtra="documentosRequeridosExtra"

                                   :lote-leiloeiro="+loteLeiloeiro"
                                   origem="auditorio"
                    />
                </div>
            </div>
            <Lance
                v-model="lanceLocal"
                :leilao="lote.leilao"
                :lote="lote"
                :habilitado="habilitado"
            />
        </q-page>
    </AuditorioLayout>
</template>
