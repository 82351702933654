<script setup>
import MenuTopo from '../../Menus/Cabecalho/Topo/Menu';
import { useQuasar } from 'quasar';
import {ref} from "vue";
const $q = useQuasar();

const corBordaBottom = ref(window.doulheTresConfig.configuracoes.rodape_top_border_color);

</script>

<template>
    <q-toolbar v-if="$q.screen.gt.sm"
               style="min-height: 35px!important; padding: 0;"
               :style="corBordaBottom ? `border-bottom: 5px solid ${corBordaBottom};` : ''"
               dense>
        <menu-topo />
    </q-toolbar>
</template>
