<script setup>
import { defineProps } from 'vue';
import useUtils from '../../Composables/useUtils';
import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    mostrarLeilao: Boolean,
    index: Number,
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    leilao
} = useLeilaoLocalComUpdates(props)

const {
    getImagem,
    formatDateWithoutSeconds,
    formatador,
    formatadorLote,
    stripHTML,
    //baixar,
    nomeStatusLote
} = useUtils();

const {
    fullscreen,
    autoplay,
    slide,
    abrirLoteArrematado
} = useLote(leilao, lote)

const {
    logoLeiloeiro
} = useGlobals();

/*
const faturado = !!(lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.fatura);
const pago = !(['0']).includes((lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.fatura?.id_status_fatura)??'0');
const urlArquivo = lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.fatura?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.arquivo?.signedUrl;
const idTipoPagamento = (+lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.fatura?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.id_tipo_pagamento);
*/
const valorLance = lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.valor_lance;
const valorComissao = lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.valor_comissao;
const valorTotal = lote.value.lances[0]?.arremates[0]?.arrematesfatura[0]?.valor_total_faturado;

</script>

<template>

   <div class="q-pa-sm col col-12"
         style="justify-content: stretch"
    >
       <!--
        <div class="full-width" style="text-align: right; ">
            <q-btn
                v-if="faturado && !pago && index===0 && idTipoPagamento === 1"
                :class="'bg-primary q-mt-sm q-mb-lg'"
                @click="baixar(urlArquivo)"
                class="text-white hide-print"
                label="Baixar Boleto para Pagamento"
                icon="mdi-download"
            />
        </div>
        -->
        <q-card class="cursor-pointer" :style="$q.screen.lt.sm ? 'width:100%' : ''">

            <q-card-section
            :horizontal="$q.screen.gt.sm"
            :style="`background-color: ${lote.status_lote?.cor_site};`"
            class="
                  text-subtitle1
                  text-white
                  text-center
                  q-py-none
                  column
                  justify-center
            "
            style="height: 2em;"
            @click="abrirLoteArrematado()" >

                {{ nomeStatusLote(lote.status_lote, +leilao.acao_ao_atingir_metrica) }}

        </q-card-section>

            <q-card-section :horizontal="$q.screen.gt.sm" class="q-pa-none">

                <q-card-section :style="$q.screen.gt.sm ? 'min-width: 550px' : ''" class="q-pa-none">

                    <q-responsive
                        :ratio="16/9" style="width: 100%;"
                    >
                        <q-carousel
                            v-model="slide"
                            v-model:fullscreen="fullscreen"
                            :autoplay="autoplay"
                            :thumbnails="fullscreen"
                            animated
                            arrows
                            control-color="white"
                            control-text-color="primary"
                            control-type="push"
                            next-icon="mdi-chevron-right"
                            prev-icon="mdi-chevron-left"
                            height="14em"
                            infinite
                            swipeable
                        >
                            <template v-slot:control>
                                <q-carousel-control
                                    :offset="[10, 10]"
                                    position="top-right"
                                >
                                    <q-btn
                                        :icon="fullscreen ? 'mdi-arrow-u-left-top' : 'mdi-fullscreen'" color="white" push
                                        text-color="primary"
                                        @click.stop="fullscreen = !fullscreen"
                                    >{{ fullscreen ? 'Voltar' : '' }}
                                    </q-btn>
                                </q-carousel-control>
                                <q-carousel-control
                                    :offset="[10, 10]"
                                    position="bottom-left"
                                    v-if="+lote.vistoria_imobiliaria?.desocupado"
                                >
                                    <q-badge size="sm" color="primary" rounded>
                                        <q-icon name="mdi-home-export-outline" size="xs" class="q-mr-xs"/> Desocupado
                                    </q-badge>
                                </q-carousel-control>
                                <q-carousel-control
                                    :offset="[10, 10]"
                                    position="bottom-left"
                                    v-if="+lote.vistoria_veicular?.sucata"
                                >
                                    <q-badge size="sm" color="primary" rounded>
                                        <q-icon name="mdi-car-wrench" size="xs" class="q-mr-xs"/> Sucata
                                    </q-badge>
                                </q-carousel-control>
                            </template>
                            <q-carousel-slide
                                v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote
                : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                                :key="key"
                                :name="1+key"
                                style="background-position: center;background-size: contain;background-repeat: no-repeat"
                                @click="abrirLoteArrematado()"
                                class="q-pa-none">
                                <q-img
                                    class="full-width full-height"
                                    loading="lazy"
                                    :src="getImagem(imagem?.arquivo, fullscreen, leilao.status_leilao?.identificador)"
                                    :ratio="16/9"
                                >
                                    <template v-slot:error>
                                        <div class="absolute-full flex flex-center bg-primary text-white">
                                            Houve um problema carregando a imagem
                                        </div>
                                    </template>
                                    <template v-slot:loading>
                                        <div class="absolute-full flex column flex-center bg-white text-primary">
                                            <img :src="logoLeiloeiro" style="width: 40%" />
                                            <p class="q-mt-sm">
                                                <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                            </p>
                                        </div>
                                    </template>
                                </q-img>
                                <div v-if="fullscreen" class="absolute-bottom custom-caption q-pb-xl">
                                    <q-card class="q-ma-lg q-ma-md-xl q-pa-md" style="overflow-y: scroll;max-height: 15vh;">
                                        <div class="text-h5">{{ lote.titulo }}</div>
                                        <div class="text-subtitle2" v-html="lote.descricao"></div>
                                    </q-card>
                                </div>
                                <div v-else class="absolute-bottom seq-caption q-pb-xl">
                                    <h5>
                                        Leilão {{ leilao.id }} - Lote {{formatadorLote(lote.sequencia)}}
                                    </h5>
                                </div>
                            </q-carousel-slide>
                        </q-carousel>
                    </q-responsive>

                </q-card-section>

                <q-card-section vertical style="width: 100%;">
                    <!--
                    <q-card-section
                        :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                        class="q-py-xs"
                        @click="abrirLoteArrematado()"
                    >
                        <div class="row no-wrap items-center"
                             @click="abrirLoteArrematado()" >
                            <div class="col desc-scroll text-h6" style="max-width: 100vw">
                                <p class="q-mb-none" style="line-height: 1.5em; height: 3em; overflow: hidden">Lote {{formatadorLote(lote.sequencia)}} - {{stripHTML(lote.titulo)}}</p>
                                <q-tooltip>
                                    Lote {{formatadorLote(lote.sequencia)}} - {{formatadorLote(lote.sequencia)}} {{stripHTML(lote.titulo)}}
                                </q-tooltip>
                            </div>
                        </div>

                    </q-card-section>

                    <q-separator/> -->

                    <q-list>

                        <q-item
                            :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                            clickable
                            @click="abrirLoteArrematado()"
                        >
                            <q-item-section avatar class="items-center">
                                <q-icon size="sm" color="primary" name="mdi-gavel"/>
                            </q-item-section>

                            <q-item-section class="text-body2" style="min-height: 55px;" >
                                <q-item-label overline style="margin-bottom: 6px;">Dados do Leilão</q-item-label>
                                <q-item-label >
                                    Nº do Leilão: <span class="text-grey-8 text-uppercase" >{{ leilao.id }} - {{ leilao.titulo}}</span>
                                <q-item-label >
                                </q-item-label>
                                    Nº do Lote: <span class="text-grey-8 text-uppercase" >{{ formatadorLote(lote.sequencia)}} - {{ stripHTML(lote.titulo) }}</span>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item
                            :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                            clickable
                            @click="abrirLoteArrematado()"
                        >
                            <q-item-section avatar class="items-center">
                                <q-icon size="sm" color="positive" name="mdi-currency-usd"/>
                            </q-item-section>

                            <q-item-section class="text-body2" >
                                <q-item-label overline style="margin-bottom: 6px;">Dados do Arremate</q-item-label>
                                <template v-if="valorLance">
                                    <q-item-label>Data do Leilão:
                                        <span class="text-grey-8 text-uppercase">
                                            <template v-if="+leilao.dois_leiloes === 0">
                                                {{ formatDateWithoutSeconds(leilao.data_hora_inicio) }}
                                            </template>
                                            <template v-if="+leilao.dois_leiloes === 1 && +leilao.primeiro_leilao_concluido === 1">
                                                {{ formatDateWithoutSeconds(leilao.data_hora_inicio_segundo_leilao) }}
                                            </template>
                                        </span>
                                    </q-item-label>
                                    <q-item-label>Valor do Lote: <span class="text-grey-8 text-uppercase" >{{ formatador.format(valorLance) }}</span></q-item-label>
                                    <q-item-label>Comissão do Leiloeiro: <span class="text-grey-8 text-uppercase" >{{ formatador.format(valorComissao) }}</span></q-item-label>
                                    <q-item-label>Total do Arremate: <span class="text-grey-8 text-uppercase" >{{ formatador.format(valorTotal) }}</span></q-item-label>
                                </template>
                                <template v-else>
                                    <q-item-label class="text-body2" >
                                        <template v-if="+lote.lance_inicial > 0">
                                            Valor do Lote: <span class="text-grey-8 text-uppercase" >{{ formatador.format(lote.lanceAtual) }}</span>
                                        </template>
                                    </q-item-label>
                                </template>

                            </q-item-section>
                        </q-item>

                    </q-list>

                    <q-separator/>

                    <q-card-actions
                        style="height: 65px;"
                        :class=" $q.dark.isActive ? 'bg-black' : 'bg-white'"
                        class="flex column justify-center items-center"
                    >
                        <q-btn
                            color="primary"
                            flat
                            icon="mdi-package-variant"
                            ripple
                            size="md"
                            stretch
                            @click="abrirLoteArrematado()"
                            style="width: 100%; height: 100%"
                        >
                            Detalhes do Arremate
                            <q-tooltip>
                                Clique aqui para ver os detalhes do Arremate
                            </q-tooltip>
                        </q-btn>
                    </q-card-actions>

                </q-card-section>

            </q-card-section>

        </q-card>
    </div>

    <q-separator />

</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 8px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h5
    margin: 0
.desc-scroll::-webkit-scrollbar
    display: none
</style>
<style>
.cssmarquee {
    overflow: hidden;
    position: relative;
    height: 35px;
}
.cssmarquee p {
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    text-align: center;
    overflow-x: visible;
    transform:translateX(0%);
    animation: cssmarquee 60s linear infinite;
}
@keyframes cssmarquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
</style>
