<script setup>
import {computed, defineProps, onMounted, onUnmounted, ref} from 'vue';
import useUtils from '../../Composables/useUtils';
import Lance from '../Leilao/Lance';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';
import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';
import useRotas from "../../Composables/useRotas";
import {Inertia} from "@inertiajs/inertia";
import {useQuasar} from "quasar";

const route = useRotas();

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    habilitado: Boolean,
    lotes: Array
});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    leilao
} = useLeilaoLocalComUpdates(props)

const {
    getImagem,
    formatDate,
    formatador,
    formatadorLote,
    nomeStatusLote
} = useUtils();

const {
    autoplay,
    slide,
    timer,
    timerModerador,
} = useLote(leilao, lote)

const {
    logoLeiloeiro,
} = useGlobals();

const $q = useQuasar();

const lanceLocal = ref(false);
const statusIniciarLeilao = ['ABERTO'];
const statusEncerrarLeilao = ['EM_PREGAO'];
const statusReabrirLeilao = ['ENCERRADO'];

const statusIniciarPregaoLote = ['ABERTO'];
const statusAberto = ['REPASSE'];
const statusRepasse = ['EM PREGAO', 'DOULHE_UMA', 'DOULHE_DUAS'];
const statusVoltarPregao = ['DOULHE_UMA', 'DOULHE_DUAS', 'REPASSE'];
const statusDoulheUma = ['EM PREGAO'];
const statusDoulheDuas = ['DOULHE_UMA'];
const statusVendido = ['DOULHE_DUAS'];
const statusNaoVendido = ['DOULHE_DUAS'];
const statusCondicional = ['DOULHE_DUAS'];
const statusTimer = ['ABERTO', 'EM PREGAO', 'DOULHE_UMA', 'DOULHE_DUAS'];

const acoesLeilao = {
    iniciarLeilao: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.iniciarLeilao', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
        })
        $q.loading.hide();
    },
    encerrarLeilao: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.encerrarLeilao', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    retirarLote: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.retirarLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    pregao: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.pregaoLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    aberto: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.abertoLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    doulheUma: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.doulheUmaLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    doulheDuas: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.doulheDuasLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    vendido: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.vendidoLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    condicional: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.condicionalLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    repasse: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.repasseLote', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    naoVendido: async () => {
        $q.loading.show({
            message: 'Executando ação solicitada...'
        })
        await fetch(
            route('moderador.mod.lote.naoVendido', { idLeilao: leilao.value.id, idLote: lote.value.id}),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
        $q.loading.hide();
    },
    anterior: async () => {
        const atual = props.lotes.findIndex(loteIt => +loteIt.id === +lote.value.id)
        const anterior = props.lotes[atual-1];
        $q.loading.show({
            message: 'Navegando para o lote ' + anterior.id,
        });
        Inertia.get(route('moderador.mod.lote', {
            idLeilao: leilao.value.id,
            idLote: anterior.id
        }),{}, {
            onSuccess: () => {
                $q.loading.hide();
            }
        });

    },
    proximo: async () => {
        const atual = props.lotes.findIndex(loteIt => +loteIt.id === +lote.value.id)
        const proximo = props.lotes[atual+1];
        $q.loading.show({
            message: 'Navegando para o lote ' + proximo.id,
        });
        Inertia.get(route('moderador.mod.lote', {
            idLeilao: leilao.value.id,
            idLote: proximo.id
        }),{}, {
            onSuccess: () => {
                $q.loading.hide();
            }
        });
    },
}

const atalhosAcoesLeilao = {
    i: 'iniciarLeilao',
    e: 'encerrarLeilao',
    'Delete': 'retirarLote',
    p: 'pregao',
    u: 'doulheUma',
    d: 'doulheDuas',
    v: 'vendido',
    n: 'naoVendido',
    c: 'condicional',
    r: 'repasse',
    a: 'aberto',
    ',': 'anterior',
    '.': 'proximo'
}

const calcEventoKeyDown = (event) => {
    console.log(event)
    if (!event.ctrlKey &&event.altKey && atalhosAcoesLeilao[event.key] && acoesLeilao[atalhosAcoesLeilao[event.key]]){
        acoesLeilao[atalhosAcoesLeilao[event.key]]();
        event.preventDefault();
    }
}

onMounted(() => {
    console.log(lote.value);
    window.addEventListener('keydown', calcEventoKeyDown, true);
})
onUnmounted(() => {
    window.removeEventListener('keydown', calcEventoKeyDown, true)
})

const incrementoLocal = ref(lote.value.incrementoLote*100);
const lanceInicialLocal = ref((lote.value.leilao.primeiro_leilao_concluido === 1 && +leilao.value.dois_leiloes === 1) ? lote.value.lance_inicial_segundo_leilao*100 : lote.value.lance_inicial*100);
const lanceMinimoLocal = ref((lote.value.lance_minimo) ? lote.value.lance_minimo*100 : lanceInicialLocal.value);

const incrementoLocalSalvo = ref(true);
const lanceInicialLocalSalvo = ref(true);
const lanceMinimoLocalSalvo = ref(true);

const salvarIncremento = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.atualizarIncremento', { idLeilao: leilao.value.id, idLote: lote.value.id}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({valor: incrementoLocal.value/100})
        });
    incrementoLocalSalvo.value = true;
    $q.loading.hide();
}

const salvarLanceInicial = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.atualizarLanceInicial', { idLeilao: leilao.value.id, idLote: lote.value.id}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({valor: lanceInicialLocal.value/100})
        });
    lanceInicialLocalSalvo.value = true;
    $q.loading.hide();
}

const salvarLanceMinimo = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.atualizarLanceMinimo', { idLeilao: leilao.value.id, idLote: lote.value.id}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({valor: lanceMinimoLocal.value/100})
        });
    lanceMinimoLocalSalvo.value = true;
    $q.loading.hide();
}

const tempoSelecionado = ref(30);
const timerIniciado = computed(() => !!lote.value.timer_moderador && !!timerModerador.value);

const definirTimer = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.defineTimer', { idLeilao: leilao.value.id, idLote: lote.value.id}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({tempo: tempoSelecionado.value})
        });
    $q.loading.hide();
}

const adicionarTempo = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.adicionarTempo', { idLeilao: leilao.value.id, idLote: lote.value.id}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify({tempo: tempoSelecionado.value})
        });
    $q.loading.hide();
}

const cancelarTimer = async () => {
    $q.loading.show({
        message: 'Executando ação solicitada...'
    })
    await fetch(
        route('moderador.mod.lote.cancelarTimer', { idLeilao: leilao.value.id, idLote: lote.value.id}),
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
        });
    $q.loading.hide();
}
const modalDescrição = () => {
    $q.dialog({
        title: `Descrição Completa do lote ${lote.value.sequencia}`,
        html: true,
        fullWidth: true,
        message: lote.value.descricao
    })
}

</script>

<template>
    <q-card class="full-width full-height">
        <q-card-section
            style="display: flex; flex-direction: row; justify-content: stretch; align-items: stretch;padding: 0"
            class="text-subtitle1 text-white text-center column justify-center">
            <div
                style="height: 50px;width: 50%;transition: all .75s ease-in-out"
                :style="`background-color: ${leilao.status_leilao?.cor_site};`"
                class="text-h5 text-white text-center column justify-center">
                Leilão: {{ leilao.status_leilao?.nome }}
            </div>
            <div
                style="height: 50px;width: 50%;transition: all .75s ease-in-out"
                :style="`background-color: ${lote.status_lote?.cor_site};`"
                class="text-h5 text-white text-center column justify-center">
                Lote: {{ nomeStatusLote(lote.status_lote, +leilao.acao_ao_atingir_metrica) }}
            </div>
        </q-card-section>
        <q-card-section class="q-pa-none">
            <q-card-section class="q-pa-none q-mb-xs flex row">
                <q-carousel
                    v-model="slide"
                    :autoplay="autoplay"
                    animated
                    arrows
                    style="height: 35vh;"
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                    infinite
                    swipeable
                    class="col-6"
                    @mouseenter="autoplay = false"
                    @mouseleave="autoplay = true"
                >
                    <q-carousel-slide
                        v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                        :key="key" :name="1+key"
                        style="background-position: center;background-size: cover;background-repeat: no-repeat"
                        class="q-pa-none">
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="getImagem(imagem?.arquivo, true, lote.status_lote?.identificador)"
                            :ratio="16/9"
                        >
                            <template v-slot:error>
                                <div class="absolute-full flex flex-center bg-primary text-white">
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div class="absolute-full flex column flex-center bg-white text-primary">
                                    <img :src="logoLeiloeiro" style="width: 40%" />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                    </q-carousel-slide>
                </q-carousel>
                <div class="col-6">
                    <div class="full-width q-px-md q-pt-md">
                        <h6 class="q-ma-none text-primary">
                            {{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}
                        </h6>
                    </div>
                    <q-list class="full-width">
                        <q-item clickable @click="modalDescrição">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-label-multiple-outline"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label style="max-height: 6vh;overflow-y: auto" class="nopad" caption v-html="lote.descricao">
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 1 && lote.proximoStatus.timestamp && +leilao.tipo_fechamento === 1" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{timer}}</q-item-label>
                                <q-item-label caption>{{lote.proximoStatus.texto}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador && statusTimer.includes(lote.status_lote.identificador)" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{timerModerador}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 1 && (lote.proximoStatusTime) && (leilao.tipo_fechamento != '0' || lote.status_lote.identificador == 'EM PREGAO')" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{lote.proximoStatusInterval}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-calendar"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label
                                    :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                    :caption="(+leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? true : false"
                                >
                                    1º Leilão: {{ formatDate(leilao.data_hora_inicio) }}
                                </q-item-label>
                                <q-item-label
                                    :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                    :caption="(+leilao.primeiro_leilao_concluido === 1) ? false : true"
                                    v-if="leilao.dois_leiloes === '1'"
                                >
                                    2º Leilão: {{ formatDate(leilao.data_hora_inicio_segundo_leilao) }}
                                </q-item-label>
                                <q-item-label v-else caption>
                                    Sem Segundo Leilão
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="positive" name="mdi-cash"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label v-if="!(lote.lanceAtual > 0)">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                                <q-item-label v-if="lote.lanceAtual > 0">Lance Atual {{ formatador.format(lote.lanceAtual) }}</q-item-label>
                                <q-item-label caption v-if="lote.lanceAtual > 0">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="positive" name="mdi-cash-fast"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label>Próximo Lance {{ formatador.format(lote.proximoLance) }}</q-item-label>
                                <q-item-label caption>Incremento {{ formatador.format(lote.incrementoLote) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <div class="flex row full-width q-pa-md">
                        <div class="flex col col-6">
                            <q-input
                                outlined
                                dense
                                prefix="R$"
                                mask="####.###.###,##"
                                reverse-fill-mask
                                unmasked-value
                                class="full-width q-pa-sm"
                                v-model="lanceInicialLocal"
                                @keyup="lanceInicialLocalSalvo = false"
                                label="Lance Inicial">
                                <template #append>
                                    <q-btn
                                        v-if="lanceInicialLocalSalvo"
                                        color="positive"
                                        flat
                                        disabled
                                        dense
                                        round
                                        icon="mdi-content-save-check" />
                                    <q-btn
                                        v-else
                                        color="warning"
                                        flat
                                        dense
                                        round
                                        @click="salvarLanceInicial"
                                        icon="mdi-content-save-alert" />
                                </template>
                            </q-input>
                        </div>
                        <div class="flex col col-6 q-pa-sm">
                            <q-input
                                outlined
                                dense
                                prefix="R$"
                                mask="####.###.###,##"
                                reverse-fill-mask
                                unmasked-value
                                class="full-width"
                                @keyup="incrementoLocalSalvo = false"
                                v-model="incrementoLocal"
                                label="Incremento">
                                <template #append>
                                    <q-btn
                                        v-if="incrementoLocalSalvo"
                                        color="positive"
                                        flat
                                        disabled
                                        dense
                                        round
                                        icon="mdi-content-save-check" />
                                    <q-btn
                                        v-else
                                        color="warning"
                                        flat
                                        dense
                                        round
                                        @click="salvarIncremento"
                                        icon="mdi-content-save-alert" />
                                </template>
                            </q-input>
                        </div>
                        <div class="flex col col-6 q-pa-sm">
                            <q-input
                                outlined
                                dense
                                prefix="R$"
                                mask="####.###.###,##"
                                reverse-fill-mask
                                unmasked-value
                                class="full-width"
                                @keyup="lanceMinimoLocalSalvo = false"
                                v-model="lanceMinimoLocal"
                                label="Mínimo Comitente">
                                <template #append>
                                    <q-btn
                                        v-if="lanceMinimoLocalSalvo"
                                        color="positive"
                                        flat
                                        disabled
                                        dense
                                        round
                                        icon="mdi-content-save-check" />
                                    <q-btn
                                        v-else
                                        color="warning"
                                        flat
                                        dense
                                        round
                                        @click="salvarLanceMinimo"
                                        icon="mdi-content-save-alert" />
                                </template>
                            </q-input>
                        </div>
                    </div>
                </div>
            </q-card-section>
            <q-card-section class="q-pa-none" style="flex: 4">
                <div class="full-width flex row">
                    <div class="q-px-xs col-6 column">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-package-variant-closed" />
                                <q-toolbar-title>
                                    Lotes
                                </q-toolbar-title>
                                <q-space />
                                <q-btn
                                    dense
                                    class="text-primary bg-white hide-print q-my-xs q-mx-xs"
                                    @click="acoesLeilao.anterior()"
                                    label="[<] Anterior"
                                />
                                <q-btn
                                    dense
                                    class="text-primary bg-white hide-print q-my-xs q-mx-xs"
                                    @click="acoesLeilao.proximo()"
                                    label="Próximo [>]"
                                />
                            </q-toolbar>
                            <div
                                style="max-height:37vh;overflow-y: auto;"
                                class="q-pa-sm flex row wrap justify-center align-items-start">
                                <slot name="botoes-lote"></slot>
                            </div>
                        </q-card>
                    </div>
                    <div class="q-px-xs col-6 column">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-gavel" />
                                <q-toolbar-title>
                                    Ações de Moderação
                                </q-toolbar-title>
                                <q-btn
                                    dense
                                    style="background-color: #FF0000"
                                    class="text-white hide-print q-my-xs"
                                    @click="acoesLeilao.retirarLote()"
                                    label="Retirar Lote [Del]"
                                    v-if="!(lote.status_lote.identificador === 'RETIRADO')"
                                />
                                <q-btn
                                    dense
                                    color="positive"
                                    class="text-white hide-print q-my-xs"
                                    @click="acoesLeilao.repasse()"
                                    label="Restaurar [Del]"
                                    v-else
                                />
                            </q-toolbar>
                            <div style="height: 30vh; overflow-y: auto;" class="q-pa-sm">
                                <p class="wrap text-subtitle2 text-bold text-center q-my-xs q-mb-sm">Ações do Leilão</p>
                                <div class="col-12"
                                     v-if="statusIniciarLeilao.includes(leilao.status_leilao.identificador)"
                                >
                                    <q-btn
                                        color="positive"
                                        class="text-white full-width hide-print q-my-xs"
                                        label="Iniciar Leilão [I]"
                                        @click="acoesLeilao.iniciarLeilao()"
                                    />
                                </div>
                                <div class="col-12"
                                     v-if="statusReabrirLeilao.includes(leilao.status_leilao.identificador)">
                                    <q-btn
                                        color="positive"
                                        class="text-white full-width hide-print q-my-xs"
                                        label="Reabrir Leilão [I]"
                                        @click="acoesLeilao.iniciarLeilao()"
                                    />
                                </div>
                                <div class="col-12"
                                     v-if="statusEncerrarLeilao.includes(leilao.status_leilao.identificador)">
                                    <q-btn
                                        color="negative"
                                        class="text-white full-width hide-print q-my-xs"
                                        label="Encerrar Leilão [E]"
                                        @click="acoesLeilao.encerrarLeilao()"
                                    />
                                </div>
                                <p class="text-subtitle2 col-12 text-bold text-center q-my-xs q-mb-sm">Ações do Lote</p>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusRepasse.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #FF6C2F"
                                        class="text-white full-width hide-print"
                                        label="Colocar em Repasse [R]"
                                        @click="acoesLeilao.repasse()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusIniciarPregaoLote.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #D96C06"
                                        class="text-white full-width hide-print"
                                        label="Iniciar Pregão [P]"
                                        @click="acoesLeilao.pregao()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusAberto.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #31CCEC"
                                        class="text-white full-width "
                                        label="Retornar para Aberto [A]"
                                        @click="acoesLeilao.aberto()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusVoltarPregao.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #D96C06"
                                        class="text-white full-width "
                                        label="Voltar ao Pregão [P]"
                                        @click="acoesLeilao.pregao()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusDoulheUma.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #E0607E"
                                        class="text-white full-width "
                                        :label="(habilitaC1EC2 && acaoAtingirMetrica) ? 'Condicional Uma [U] ':'Doulhe Uma [U]'"
                                        @click="acoesLeilao.doulheUma()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusDoulheDuas.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #CA054D"
                                        class="text-white full-width "
                                        :label="(habilitaC1EC2 && acaoAtingirMetrica) ? 'Condicional Duas [D] ':'Doulhe Duas [D]'"
                                        @click="acoesLeilao.doulheDuas()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusVendido.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #BCCF30"
                                        class="text-white full-width "
                                        label="Vendido [V]"
                                        @click="acoesLeilao.vendido()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusNaoVendido.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        color="negative"
                                        class="text-white full-width "
                                        label="Não Vendido [N]"
                                        @click="acoesLeilao.naoVendido()"
                                    />
                                </div>
                                <div style="width: 50%; display: inline-block" class="q-my-none q-pa-xs"
                                     v-if="statusCondicional.includes(lote.status_lote.identificador)">
                                    <q-btn
                                        style="background-color: #FF6C2F"
                                        class="text-white full-width "
                                        label="Condicional [C]"
                                        @click="acoesLeilao.condicional()"
                                    />
                                </div>
                                <div style="width: 100%; display: flex;flex-direction: row;align-items: center;" class="q-my-none q-pa-xs">
                                    <div style="width: 50%; display: inline-block" class="q-my-none">
                                        <q-select
                                            outlined
                                            dense
                                            class="full-width"
                                            v-model="tempoSelecionado"
                                            map-options
                                            emit-value
                                            :options="[
                                                { label: '10s', value: 10},
                                                { label: '15s', value: 15},
                                                { label: '20s', value: 20},
                                                { label: '30s', value: 30},
                                                { label: '45s', value: 45},
                                                { label: '1m', value: 60},
                                                { label: '1m 15s', value: 75},
                                                { label: '1m 30s', value: 80},
                                                { label: '1m 45s', value: 95},
                                                { label: '2m', value: 120},
                                            ]"
                                            label="Tempo"></q-select>
                                    </div>
                                    <div style="width: 50%; display: inline-block" class="q-my-none q-pl-xs" v-if="!timerIniciado">
                                        <q-btn
                                            dense
                                            color="primary"
                                            class="full-width"
                                            label="Iniciar Timer"
                                            @click="definirTimer"
                                            icon="mdi-play"
                                        />
                                    </div>
                                    <div style="width: 33.30%; display: inline-block" class="q-my-none q-pl-xs" v-if="timerIniciado">
                                        <q-btn
                                            dense
                                            color="primary"
                                            class="full-width"
                                            label="Adicionar"
                                            @click="adicionarTempo"
                                            icon="mdi-plus"
                                        />
                                    </div>
                                    <div style="width: 33.30%; display: inline-block" class="q-my-none q-pl-xs" v-if="timerIniciado">
                                        <q-btn
                                            dense
                                            color="negative"
                                            class="full-width"
                                            label="Cancelar"
                                            @click="cancelarTimer"
                                            icon="mdi-stop"
                                        />
                                    </div>
                                </div>
                            </div>
                        </q-card>
                    </div>
                </div>
            </q-card-section>
            </q-card-section>
        <Lance
            v-model="lanceLocal"
            :leilao="lote.leilao"
            :lote="lote"
            :habilitado="habilitado"
        />
    </q-card>
</template>
<style>
.nopad > p{
    margin: 0;
}
</style>
