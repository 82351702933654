import script from "./MenuMinhaConta.vue?vue&type=script&setup=true&lang=js"
export * from "./MenuMinhaConta.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QAvatar,QIcon});
